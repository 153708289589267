import { useState, useCallback, useEffect } from 'react';
import useFetch from './useFetch';
import useWebsocket from './useWebsocket';
import useFixtures from './useFixtures';
import Logger from '../utils/logger';
import { useUserSession } from './useLocalStorage';
import { AccountType, APICode } from '../models/types';
import { env } from '../utils/util';

const isFixtureMode = env('REACT_APP_USE_FIXTURES');

export const FetchMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const APIType = {
  FETCH: 0,
  WEBSOCKET: 1
};

const urlParamRegex = new RegExp('(:[a-zA-Z0-9-_]+)', 'gi');

export class APICaller {
  constructor(url, method, lang_group, { body = {}, auth = true, type = APIType.FETCH, errors = {}, clazz = null, accountType = AccountType.TRIP_COMPANY } = {}) {
    this.method = method;
    this.url = url;
    this.body = body;
    this.auth = auth;
    this.type = type;
    this.errors = errors;
    this.lang_group = lang_group;
    this.uriVars = this.url.match(urlParamRegex) || [];
    this.clazz = clazz;
    this.accountType = accountType;
  }

  hasErrorCode(message) {
    const error = message ? (this.errors.find(e => e.toUpperCase() === message.toUpperCase())) : false;
    return !!error;
  }

  validate(params, outParams = {}) {
    let valid = true;
    let invalidFields = [];

    for (const key in this.body) {
      if (this.body[key].required === true && (!params.hasOwnProperty(key) || params[key] === null || params[key] === '' || params[key] === undefined )) {
        valid = false;
        invalidFields.push(key);
      }
      outParams[key] = params[key];
    }

    for (const param of this.uriVars) {
      const key = param.slice(1);
      outParams[key] = params[key];
    }

    return { result: valid, fields: invalidFields };
  }
};

const useAPI = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [userSession, setUserSession, , getUserSession] = useUserSession();

  const fetchAPI = useFetch(setIsLoading);
  const wsAPI = useWebsocket();
  const fixtureAPI = useFixtures();

  useEffect(() => {
    setIsLoading(wsAPI.isLoading);
  }, [wsAPI.isLoading]);

  useEffect(() => {
    setIsLoading(fixtureAPI.isLoading);
  }, [fixtureAPI.isLoading]);

  const callApi = useCallback(async (api, params = {}) => {
    const logger = new Logger({ name: 'useAPI', showDate: false, showFile: false });

    if (api.auth) {
      if (api.uriVars.indexOf(':accountId') >= 0) {
        if (params instanceof FormData) {
          params.append("accountId", params['accountId'] || getUserSession().account);
        } else {
          let accountId = params['accountId'] || getUserSession().account;
          params['accountId'] = accountId;
        }
      }
    }
    logger.debug('START CALL API', api, params);

    if (!(api instanceof APICaller)) {
      return { error: APICode.UNKNOWN_REQUEST };
    }

    let body = {};
    if (params instanceof FormData) {
      body = params;
    } else {
      let validation = api.validate(params, body);
      if (!validation.result) {
        return { error: APICode.MISSING_REQUIRED_FIELDS, fields: validation.fields };
      }
    }

    let response = { error: APICode.UNKNOWN_REQUEST };

    try {
      if (isFixtureMode) {
        response = await fixtureAPI.callApi(api, body);
        if (api.hasErrorCode(response.error)) {
          response.message = response.error;
        } else if (response.error) {
          response.message = response.error;
        }
      } else if (api.type === APIType.FETCH) {
        response = await fetchAPI.callApi(api, body);
        if (api.hasErrorCode(response.error)) {
          response.message = response.error;
        } else if (response.error) {
          response.message = response.error;
        }
      } else if (api.type === APIType.WEBSOCKET) {
        response = await wsAPI.callApi(api, body);
        if (api.hasErrorCode(response.error)) {
          response.message = response.error;
        } else if (response.error) {
          response.message = response.error;
        }
      }
    } catch(e) {
      console.error(e);
    }

    if (response.error === APICode.UNAUTHORIZED) {
      setUserSession({ ...userSession, account: null, logged_in: false });
    }

    return response;
  }, [fetchAPI, fixtureAPI, wsAPI, (userSession && userSession.account)]);

  return {
    isLoading,
    callApi,
  };
}

export default useAPI;