import ClassHelper from './ClassHelper';
import Transport from './Transport';
import User from './User';

class ScheduleTransport extends ClassHelper {
  constructor({ _id = null, id = null, transport, guids, drivers } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.transport = this.ref(transport, Transport);
    this.guids = this.ref(guids, User);
    this.drivers = this.ref(drivers, User);
  }
}

export default ScheduleTransport;