import ClassHelper from './ClassHelper';

class ClientDetails extends ClassHelper {
  constructor({ value = null, label, meta: { phone, birthday } = {}} = {}) {
    super(...arguments);
    this.value = value;
    this.label = label;
    this.meta = {
      phone,
      birthday
    };
  }
}

export default ClientDetails;