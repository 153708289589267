import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';
import Image from './Image';

class ImageGallery extends React.Component {
  render() {
    return (
      <Carousel interval={null}>
        {this.props.list.map((img, i) => (
          <Carousel.Item key={i}>
            <Image className="d-block w-100" src={typeof img.getUrl === 'function' ? img.getUrl(1920) : img.path} alt={img.title} />
            <Carousel.Caption>
              <p>{img.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

ImageGallery.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired
};

export default ImageGallery;