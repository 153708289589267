import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportParams from '../models/ReportParams';
import Button from './Button';
import Field, { LINE_MODE } from './Field';
import SelectDate, { APPLY_MODE, DAY_PICKER_MODE } from './SelectDate';
import Checkbox from './Checkbox';
import DateFormat from './DateFormat';
import InputAutocomplete from './InputAutocomplete';
import Erase from './icons/Erase';
import Filter from './icons/Filter';
import Search from './icons/Search';

export const SHOW_MODE = {
  AUTO: 'auto',
  ALWAYS: 'always'
};

const ReportFilter = ({ apiHandler, sort, filters = [], className = '', placeholder, autosuggest, output, onSearch, show = SHOW_MODE.AUTO }) => {
  const {t} = useTranslation(['common']);

  const [query, setQuery] = useState('');
  const [list, setList] = useState([]);
  const [showFilter, setShowFilter] = useState(show === SHOW_MODE.ALWAYS);
  const [filterValues, setFilterValues] = useState({});
  const [autolist, setAutolist] = useState([]);

  const handleAutocomplete = async () => {
    let params = new ReportParams({ filters: filterValues }).removeEmpty();

    if (filters) {
      for (let field in params.filters) {
        let filter = filters.find((f) => f.key === field);
        if (filter) {
          let type = 'text';
          let dot = filter.type.indexOf('.');
          if (dot > 0) {
            type = filter.type.substring(dot + 1) || 'text';
          }
          if (type === 'date') {
            let fieldParam = params.filters[field];
            if (typeof fieldParam === 'string' && fieldParam.length > 0) {
              params.filters[field] = fieldParam;
            } else if (fieldParam instanceof Date) {
              params.filters[field] = DateFormat.Request({ date: fieldParam });
            } else if (fieldParam instanceof Array) {
              params.filters[field] = fieldParam;
            } else if (typeof fieldParam === 'object' && fieldParam !== null) {
              for (let subfield in fieldParam) {
                if (fieldParam[subfield] instanceof Date) {
                  params.filters[field][subfield] = DateFormat.Request({ date: fieldParam[subfield] });
                }
              }
            }
          }
        }
      }
    }

    let _list = await apiHandler(params);
    onSearch && onSearch(params, _list);
    setList(_list);
  };

  const handleApplyFilter = () => {
    handleAutocomplete();
  };

  const handleFilter = useCallback((query, key, subkey) => {
    query = subkey !== undefined ? { ...filterValues[key], [subkey]: query } : query;
    setFilterValues({ ...filterValues, [key]: query });
  }, [filterValues]);

  const handleFilterCheckbox = useCallback((query, checked, key, subkey) => {
    let values = subkey !== undefined ? (filterValues[key] && filterValues[key][subkey] ? filterValues[key][subkey] : []) : (filterValues[key] || []);
    let idx = values.indexOf(query);
    if (!checked) {
      if (idx >= 0) {
        values.splice(idx, 1);
      }
    } else {
      if (idx === -1) {
        values.push(query);
      }
    }
    let filter = subkey !== undefined ? { ...filterValues[key], [subkey]: values } : values;
    setFilterValues({ ...filterValues, [key]: filter });
  }, [filterValues]);

  const handleQuery = useCallback((query) => {
    let value = null;
    if (autosuggest) {
      let item = autolist.find((item) => item.title === query);
      if (item) {
        value = item.value;
      }
    }
    setFilterValues({ ...filterValues, [autosuggest.key]: value });
    setQuery(query);
  }, [query, autolist, filterValues]);

  // const handleEraseFilter = useCallback((key) => {
  //   setFilterValues({ ...filterValues, [key]: null });
  // }, [filterValues]);

  useEffect(() => {
    if (show === SHOW_MODE.ALWAYS) {
      handleAutocomplete();
    }
  }, [filterValues]);

  useEffect(() => {
    if (autosuggest && autosuggest.apiHandler) {
      autosuggest.apiHandler(query).then(setAutolist).catch(() => setAutolist([]));
    }
  }, [query]);


  useEffect(() => {
    handleAutocomplete();
  }, []);

  return (
    <>
      {autosuggest && (
        <>
          <div className={`input-group filter-field ${className}`}>
            <div className="input-group-text">
              <Search />
            </div>
            <InputAutocomplete
              value={query}
              list={autolist.map((item) => item.title)}
              placeholder={placeholder || ''}
              onChange={handleQuery}
              onInput={setQuery}
            />
            {(show === SHOW_MODE.AUTO) && filters && filters.length > 0 && (
              <div className="input-group-text" onClick={() => setShowFilter(!showFilter)}>
                <Filter />
              </div>
            )}
            <div className="input-group-text" onClick={() => setQuery('')}>
              <Erase />
            </div>
          </div>
        </>
      )}
      {filters && filters.length > 0 && showFilter && (
        <>
          <div>
            {filters.map((filter) => {
              let filterList = null;
              let type = 'text';
              let dot = filter.type.indexOf('.');
              if (dot > 0) {
                type = filter.type.substring(dot + 1) || 'text';
              }

              if (filter.type.startsWith('value')) {
                if (type === 'date') {
                  filterList = <Field className="filter-line" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.ONE}>
                    <SelectDate
                      className="filter-input"
                      name={filter.key}
                      value={filterValues[filter.key] || ''}
                      mode={DAY_PICKER_MODE.SINGLE}
                      onChange={(date) => handleFilter(date, filter.key)}
                      apply={APPLY_MODE.AUTO}
                      erase={true}
                    />
                  </Field>
                } else {
                  filterList = <Field className="filter-line" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.ONE}>
                    <input type={type} className="filter-input" name={filter.key} value={filterValues[filter.key] || ''} onBlur={(e) => { handleFilter(e.target.value, filter.key); }} />
                  </Field>
                }
              } else if (filter.type.startsWith('between')) {
                let $gte = `${filter.key}.$gte`;
                let $lte = `${filter.key}.$lte`;
                filterList = <Field className="filter-line" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.ONE}>
                  <input type={type} className="filter-input" name={$gte} max={(filterValues[filter.key] && filterValues[filter.key].$lte) || ''} value={(filterValues[filter.key] && filterValues[filter.key].$gte) || ''} onBlur={(e) => { handleFilter(e.target.value, filter.key, '$gte'); }} />
                  -
                  <input type={type} className="filter-input" name={$lte} min={(filterValues[filter.key] && filterValues[filter.key].$gte) || ''} value={(filterValues[filter.key] && filterValues[filter.key].$lte) || ''} onBlur={(e) => { handleFilter(e.target.value, filter.key, '$lte'); }} />
                </Field>
              } else if (filter.type === 'select') {
                filterList = <Field className="filter-line" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.ONE}>
                  {(type === 'date') && (
                    <select className="filter-input" name={filter.key} onChange={(e) => { handleFilter(e.target.value, filter.key); }}>
                      {filter.list.map((item) => (
                        <option key={item.id} value={item.id}>{item.title || item.id}</option>
                      ))}
                    </select>
                  )}
                  {(type === 'text') && (
                    <select className="filter-input" name={filter.key} onChange={(e) => { handleFilter(e.target.value, filter.key); }}>
                      {filter.list.map((item) => (
                        <option key={item.id} value={item.id}>{item.title || item.id}</option>
                      ))}
                    </select>
                  )}
                </Field>
              } else if (filter.type === 'badge') {
                filterList = <Field className="filter-line toggle-list" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.TWO}>
                  <Checkbox.Group>
                    {filter.list.map((item) => (
                      <Checkbox.Button key={item.id} title={item.title || item.id} checked={((filterValues[filter.key] && filterValues[filter.key].$in) || []).indexOf(item.id) >= 0} value={item.id} onChange={(value, checked) => {handleFilterCheckbox(value, checked, filter.key, '$in')}} />
                    ))}
                  </Checkbox.Group>
                </Field>
              } else {
                filterList = <Field className="filter-line" key={filter.key} title={filter.title || t(`common:filter.${filter.key}`)} mode={LINE_MODE.ONE}>
                  <input type="text" className="filter-input" name={filter.key} value={filterValues[filter.key] || ''} onBlur={(e) => { handleFilter(e.target.value, filter.key); }} />
                </Field>
              }
              return filterList;
            })}
          </div>
          {(show === SHOW_MODE.AUTO) && (
            <div>
              <Button onClick={handleApplyFilter}>{t('common:button.save')}</Button>
            </div>
          )}
        </>
      )}
      {output(list)}
    </>
  );
};

ReportFilter.propTypes = {
  apiHandler: PropTypes.func.isRequired,
  mode: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autosuggest: PropTypes.any,
  sort: PropTypes.array,
  filters: PropTypes.array,
  output: PropTypes.func.isRequired,
  onSearch: PropTypes.func
};


export default ReportFilter;