import ClassHelper from './ClassHelper';
import Excursion from './Excursion';
import Schedule from './Schedule';
import Transport from './Transport';

class OrderDetails extends ClassHelper {
  constructor({ _id = null, id = null, number, date, schedule, excursion, seats = [], transports = [], paidAmount, refundAmount, paymentMethod, status} = {}) {
    super(...arguments);
    this.id = _id || id;
    this.number = number;
    this.date = this.obj(date, Date);
    this.schedule = this.ref(schedule, Schedule);
    this.excursion = this.ref(excursion, Excursion);
    this.seats = this.array(seats, Number);
    this.transports = this.array(transports, Transport);
    this.paidAmount = paidAmount;
    this.refundAmount = refundAmount;
    this.paymentMethod = paymentMethod; // PaymentMethod
    this.status = status; // OrderStatus
  }
}

export default OrderDetails;