import ClassHelper from './ClassHelper';
import Transport from './Transport';

class Seat extends ClassHelper {
  constructor({ _id = null, id = null, seats = [], transport, count } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.seats = this.array(seats, Number);
    this.transport = this.ref(transport, Transport);
    this.count = count;
  }
}

export default Seat;