import ClassHelper from './ClassHelper';
import ExcursionSubdivision from './ExcursionSubdivision';
import Schedule from './Schedule';

class ScheduleExcursionDetails extends ClassHelper {
  constructor({ _id = null, id = null, title, status, subdivisions = [], schedules = {} } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.status = status; // Status
    this.subdivisions = this.array(subdivisions, ExcursionSubdivision);
    this.schedules = {};
    for (let date in schedules) {
      this.schedules[date] = this.array(schedules[date], Schedule);
    }
  }
}

export default ScheduleExcursionDetails;