import ClassHelper from './ClassHelper';
import Excursion from './Excursion';
import PlaceDetails from './PlaceDetails';
import Schedule from './Schedule';
import Transport from './Transport';

class RouteDetails extends ClassHelper {
  constructor({ _id = null, schedule, number, date, excursions = [], transports = [], places = []} = {}) {
    super(...arguments);
    this.schedule = this.ref(schedule || _id, Schedule);
    this.number = number;
    this.date = date;
    this.excursions = this.array(excursions, Excursion);
    this.transports = this.array(transports, Transport);
    this.places = this.array(places, PlaceDetails);
  }
}

export default RouteDetails;