import ClassHelper from './ClassHelper';
import Account from './Account';
import DynamicPrice from './DynamicPrice';
import User from './User';

class DiscountBase extends ClassHelper {
  constructor({ type, title, account, cntActivations, startDate, endDate, discount, appliesTo, forGroups, users = [], status, deletedAt } = {}) {
    super(...arguments);
    this.type = type; // DiscountType
    this.title = title;
    this.account = this.ref(account, Account, true);
    this.cntActivations = cntActivations;
    this.startDate = this.obj(startDate, Date);
    this.endDate = this.obj(endDate, Date);
    this.discount = this.obj(discount, DynamicPrice);
    this.appliesTo = appliesTo; // AppliesTo
    this.forGroups = forGroups; // ForGroup
    this.users = this.array(users, User, true);
    this.status = status; // Status
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default DiscountBase;