import ClassHelper from './ClassHelper';
import Account from './Account';
import Excursion from './Excursion';
import Order from './Order';

class Reward extends ClassHelper {
  constructor({ _id = null, id = null, type, account, excursion, order, date, amount } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.type = type; // RewardType
    this.account = this.ref(account, Account);
    this.excursion = this.ref(excursion, Excursion);
    this.order = this.ref(order, Order);
    this.date = this.obj(date, Date);
    this.amount = amount;
  }
}

export default Reward;