import { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Info from '../../components/Info';
import DateFormat from '../../components/DateFormat';
import Price, { CURRENCY } from '../../components/Price';
import Phone from '../../components/Phone';
import RepositoryModel from '../../components/RepositoryModel';

const OrderInfo = ({order}) => {
  const {t} = useTranslation(['common', 'sale']);

  const repository = useContext(RepositoryContext);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <Info title={t('sale:order_info.excursion')}>
          {order.excursion.title}
        </Info>
        <Info title={t('sale:order_info.departure')}>
          <DateFormat.DateTime date={order.schedule.date}/>
        </Info>
        <Info title={t('sale:order_info.client')}>
          <div>{order.user.profile.fullName}</div>
          <div><Phone value={order.user.phone} /></div>
        </Info>
        <Info title={t('sale:order_info.passengers')}>
          {order.passengers.map((passenger) => (
            <div key={passenger.id}>
              {passenger.count} <RepositoryModel type="Price" uid={passenger.price.id} render={(model) => model.title} />
            </div>
          ))}
        </Info>
        <Info title={t('sale:order_info.additional')}>
          {order.wishes.map((wish, i) => (
            <Fragment key={i}>{t(`common:wishes.${wish}`)}</Fragment>
          ))}
        </Info>
        <Info title={t('sale:order_info.payment')}>
          <Price currency={CURRENCY.RUB} value={order.paymentDetails.amount} /> - {t(`common:payment_method.${order.paymentDetails.method}`)}
        </Info>
        {order.place && (
          <Info title={t('sale:order_info.place')}>
            {order.place.title}
          </Info>
        )}
        {order.address && (
          <Info title={t('sale:order_info.address')}>
            {order.address}
          </Info>
        )}
      </div>
    </div>
  )
}

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired
};

export default OrderInfo;