import ClassHelper from './ClassHelper';

class ExcursionFaq extends ClassHelper {
  constructor({ _id = null, id = null, whatToBring, whatToExpect, suitableFor } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.whatToBring = whatToBring;
    this.whatToExpect = whatToExpect;
    this.suitableFor = suitableFor;
  }
}

export default ExcursionFaq;