import ClassHelper from './ClassHelper';

class Limit extends ClassHelper {
  constructor({ _id = null, id = null, value, cntSeats, stopSelling = true, forceStopSelling = false } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.value = value;
    this.cntSeats = cntSeats;
    this.stopSelling = !!stopSelling;
    this.forceStopSelling = !!forceStopSelling;
  }
}

export default Limit;