import ClassHelper from './ClassHelper';
import Account from './Account';
import User from './User';
import TransportConfig from './TransportConfig';

class Transport extends ClassHelper {
  constructor({ _id = null, id = null, title, type, account, number, config, drivers = [], status, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.type = type; // TransportType
    this.account = this.ref(account, Account);
    this.number = number;
    this.config = this.obj(config, TransportConfig);
    this.drivers = this.array(drivers, User, true);
    this.status = status; // Status
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Transport;