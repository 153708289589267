import ClassHelper from './ClassHelper';

class Contact extends ClassHelper {
  constructor({ name, email, phone } = {}) {
    super(...arguments);
    this.name = name;
    this.email = email;
    this.phone = phone;
  }
}

export default Contact;