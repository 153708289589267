const FixtureAuth = {
  signIn: {
    'success': '{}'
  },
  signOut: {
    'success': '{}'
  },
  checkPhone: {
    'success': '{}'
  },
  setPassword: {
    'success': '{}'
  }
};

export default FixtureAuth;