import ClassHelper from './ClassHelper';

class Requisite extends ClassHelper {
  constructor({ name, country, email, address, ogrn, inn, kpp } = {}) {
    super(...arguments);
    this.name = name;
    this.country = country;
    this.email = email;
    this.address = address;
    this.ogrn = ogrn;
    this.inn = inn;
    this.kpp = kpp;
  }
}

export default Requisite;