import { callToDevice, attachAndroidEvent } from './events';

let isNetworkAvailable = true;

const subscribers = new Set();

const handleNetworkState = (e) => {
  isNetworkAvailable = e.detail;

  // send isNetworkAvailable to each subscriber
  subscribers.forEach((callback) => {
    callback(isNetworkAvailable);
  });
};

if (window.AndroidInterface) {
  attachAndroidEvent('network-state', handleNetworkState);

  if (typeof window.AndroidInterface.getNetworkState === 'function') {
    callToDevice('getNetworkState').then((state) => {
      isNetworkAvailable = state;
    }).catch((e) => {
      isNetworkAvailable = false;
    });
  }
}

const subscribe = (callback) => {
  subscribers.add(callback);
  callback(isNetworkAvailable);

  return () => {
    subscribers.delete(callback);
  };
};

export default {
  subscribe,
  isAvailable: () => isNetworkAvailable,
};
