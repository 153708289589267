class OrderSession {
  constructor({ name, first_name, last_name, middle_name, phone, birthday, user_id, excursion, subdivision, schedule, passengers = [], payment_method,
    contact_phones = [], wishes = [], place, address, passports, comment, discount, date, time
  } = {}) {
    this.name = name;
    this.first_name = first_name;
    this.last_name = last_name;
    this.middle_name = middle_name;
    this.birthday = birthday;
    this.phone = phone;
    this.user_id = user_id;
    this.excursion = excursion;
    this.subdivision = subdivision;
    this.schedule = schedule;
    this.passengers = passengers;
    this.payment_method = payment_method;
    this.contact_phones = contact_phones;
    this.wishes = wishes;
    this.place = place;
    this.address = address;
    this.passports = passports;
    this.comment = comment;
    this.discount = discount ? {
      value: discount.value,
      type: discount.type,
      id: discount.id
    } : null;
    // this.sellerId = sellerId;
    this.date = date;
    this.time = time;
  }

  getName() {
    return `${this.first_name} ${this.last_name} ${this.middle_name}`.trim();
  }
}

export default OrderSession;