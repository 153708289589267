import ClassHelper from './ClassHelper';
import Account from './Account';

class Subdivision extends ClassHelper {
  constructor({ _id = null, id = null, account, title, status, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.title = title;
    this.status = status; // Status
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Subdivision;