import ClassHelper from './ClassHelper';
import Place from './Place';

class ExcursionPlace extends ClassHelper {
  constructor({ _id = null, id = null, place, time } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.time = time;
    this.place = this.ref(place, Place);
  }

  // getTime() {
  //   return this.time ? `${(~~(this.time / 60)).toString().padStart(2, '0')}:${(this.time % 60).toString().padStart(2, '0')}` : '00:00';
  // }

  getTime() {
    let timeObj = {
      days: 0,
      hours: 0,
      minutes: 0
    };
    if (this.time) {
      let d = this.time;
      timeObj.days = Math.trunc(d / 86400);
      d %= 24 * 3600;
      timeObj.hours = Math.trunc(d / 3600);
      d %= 3600;
      timeObj.minutes = Math.trunc(d / 60);
    }
    return `${timeObj.hours.toString().padStart(2, '0')}:${timeObj.minutes.toString().padStart(2, '0')}`;
  }
}

export default ExcursionPlace;