import ClassHelper from './ClassHelper';
import Account from './Account';
import Location from './Location';
import Subdivision from './Subdivision';
import Place from './Place';

class Point extends ClassHelper {
  constructor({ _id = null, id = null, title, account, status, address, subdivision, location, openTime, closeTime, places = [], deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.account = this.ref(account, Account);
    this.status = status; // Status
    this.openTime = openTime;
    this.closeTime = closeTime;
    this.address = address;
    this.location = this.obj(location, Location);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.places = this.array(places, Place, true);
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Point;