import ClassHelper from './ClassHelper';
import Account from './Account';
import Category from './Category';
import ExcursionFaq from './ExcursionFaq';
import ExcursionSeo from './ExcursionSeo';
import ExcursionSubdivision from './ExcursionSubdivision';
import File from './File';
import FreeService from './FreeService';
import Moderation from './Moderation';

class Excursion extends ClassHelper {
  constructor({ _id = null, id = null, account, type, pickupType, title, description, url, categories, freeServices, canBeCancelledBefore, seo, cover, video, images,
    subdivisions, faq, rating, passportRequired, publishingRequired, usingInsideAccount, status, moderation, publishedAt, deletedAt
  } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.type = type; // ExcursionType
    this.pickupType = pickupType; // ExcursionPickupType
    this.title = title;
    this.description = description;
    this.url = url;
    this.categories = this.array(categories, Category, true);
    this.freeServices = this.array(freeServices, FreeService, true);
    this.canBeCancelledBefore = canBeCancelledBefore;
    this.seo = this.obj(seo, ExcursionSeo);
    this.cover = this.obj(cover, File);
    this.video = this.obj(video, File);
    this.images = this.array(images, File);
    this.subdivisions = this.array(subdivisions, ExcursionSubdivision);
    this.faq = this.obj(faq, ExcursionFaq);
    this.rating = rating;
    this.passportRequired = passportRequired;
    this.publishingRequired = publishingRequired;
    this.usingInsideAccount = usingInsideAccount;
    this.status = status; // Status
    this.moderation = this.obj(moderation, Moderation);
    this.publishedAt = this.obj(publishedAt, Date);
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Excursion;