class ReportParams {
  constructor({ filters, sort, page, skip, limit } = {}) {
    this.filters = filters instanceof Array ? Object.fromEntries(filters.map(o => [o, null])) : (typeof filters === 'object' ? { ...filters } : {});
    this.sort = sort instanceof Array ? Object.fromEntries(sort.map(o => [o, null])) : (typeof sort === 'object' ? sort : {});
    this.page = page || 1;
    this.skip = skip || 0;
    this.limit = limit || 25;
  }

  removeEmpty() {
    for (let field in this.filters) {
      if (this.filters[field] === '' || this.filters[field] === null || this.filters[field] === undefined || this.filters[field] === -1 || this.filters[field] === '-1' || (this.filters[field] instanceof Array && this.filters[field].length === 0)) {
        delete this.filters[field];
      } else if (!(this.filters[field] instanceof Array) && !(this.filters[field] instanceof Date) && typeof this.filters[field] === 'object') {
        let isEmpty = true;
        for (let subfield in this.filters[field]) {
          if (this.filters[field][subfield] !== '' && this.filters[field][subfield] !== null && this.filters[field][subfield] !== undefined && this.filters[field][subfield] !== -1 && this.filters[field][subfield] !== '-1' && !(this.filters[field][subfield] instanceof Array && this.filters[field][subfield].length === 0)) {
            isEmpty = false;
          }
        }
        if (isEmpty) {
          delete this.filters[field];
        }
      }
    }
    return this;
  }
};

export default ReportParams;