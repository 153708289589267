import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import API from '../../server/api';
import NavBar from '../../components/NavBar';
import Price, { CURRENCY } from '../../components/Price';
import InfoDescription from '../../components/InfoDescription';
import Table from '../../components/Table';
import CashboxDetails from '../../models/CashboxDetails';
import Button, { BUTTON_POSITION } from '../../components/Button';
import DatePeriod from '../../components/DatePeriod';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CollectionType } from '../../models/types';
import Modal from '../../components/Modal';
import SelectDate from './select_date';
import HandCash from './hand_cash';
import TakeSalary from './take_salary';
import DateFormat from '../../components/DateFormat';
import log from '../../utils/logger';

dayjs.extend(utc);
dayjs.extend(timezone);

const Cashbox = () => {
  const {t} = useTranslation(['common', 'cashbox']);

  const repository = useContext(RepositoryContext);

  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));

  const [cashbox, setCashbox] = useState(new CashboxDetails());

  const [showRangeModal, setShowRangeModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showSalaryModal, setShowSalaryModal] = useState(false);

  const handleCloseRangeModal = useCallback(() => {
    setShowRangeModal(false);
  }, []);

  const handleCloseCashModal = useCallback(() => {
    setShowCashModal(false);
  }, []);

  const handleCloseSalaryModal = useCallback(() => {
    setShowSalaryModal(false);
  }, []);

  const handleApplyRangeModal = useCallback((range) => {
    setShowRangeModal(false);
    setStartDate(dayjs(range.from));
    setEndDate(dayjs(range.to || range.from));
  }, []);

  const loadCashbox = useCallback(async () => {
    repository.action(API.cashier.cashbox, {
      fromDate: DateFormat.Request({ date: new Date(startDate.toDate()) }),
      toDate: DateFormat.Request({ date: endDate.toDate() })
    }).then((cashbox) => {
      setCashbox(cashbox);
    }).catch((error) => {
      log.error(error);
      setCashbox(new CashboxDetails());
    });
  }, [startDate, endDate]);

  useEffect(() => {
    loadCashbox();
  }, [startDate, endDate]);

  return (
    <div className="trip-app">
      <NavBar title={t('cashbox:cashbox.title')} />
      <div className="trip-app-body">
        <Table cols={2}>
          <Table.Row>
            <Table.Cell>
              <InfoDescription description={t('cashbox:total.total_cash')}>
                <Price currency={CURRENCY.RUB} value={cashbox.total.totalCash} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:total.total_salary')}>
                <Price currency={CURRENCY.RUB} value={cashbox.total.totalSalary} />
              </InfoDescription>
            </Table.Cell>
          </Table.Row>
        </Table>

        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} onClick={() => setShowCashModal(true)}>{t('cashbox:button.hand_cash')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} onClick={() => setShowSalaryModal(true)}>{t('cashbox:button.take_salary')}</Button>
        </Button.Panel>

        <div className="header-line">
          <span>{t('cashbox:cashbox.total')}</span>
          <span className="link-action">
            <DatePeriod start={startDate.format('MM-DD-YYYY')} end={endDate.format('MM-DD-YYYY')} onClick={() => setShowRangeModal(true)}/>
          </span>
        </div>

        <Table cols={3}>
          <Table.Row>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_cash')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalAmount} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_other_amount')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalOtherAmount} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_amount')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalAmount} />
              </InfoDescription>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_refund_cash')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalRefundCash} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_other_refunds')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalOtherRefunds} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_refunds')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalRefunds} />
              </InfoDescription>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.collected_amount')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.collectedAmount} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.salary_withdrawn')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.salaryWithdrawn} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription description={t('cashbox:stat.total_orders')}>
                <Price currency={CURRENCY.RUB} value={cashbox.stat.totalOrders} />
              </InfoDescription>
            </Table.Cell>
          </Table.Row>
        </Table>

        <div className="header-line">{t('cashbox:cashbox.history')}</div>

        <Table cols={3}>
          {cashbox.history.map((h, i) => (
            <Table.Row key={i}>
              <Table.Cell colspan={2}>
                {h.type === CollectionType.COLLECTION ?
                  <InfoDescription description={t('cashbox:history.take', { name: 'aaa sss ddd'})}>
                    {t(`cashbox:history.${h.type}`, { date: h.day, time: h.time })}
                  </InfoDescription>
                  :
                  <InfoDescription>
                    {t(`cashbox:history.${h.type}`, { date: h.day, time: h.time })}
                  </InfoDescription>
                }
              </Table.Cell>
              <Table.Cell>
                <Price currency={CURRENCY.RUB} value={h.amount} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>

        <Modal show={showRangeModal} onHide={handleCloseRangeModal}>
          <Modal.Header closeButton>
            {t('cashbox:date_period.title')}
          </Modal.Header>
          <Modal.Body>
            <SelectDate start={startDate.toDate()} end={endDate.toDate()} onApply={handleApplyRangeModal} onCancel={handleCloseRangeModal}/>
          </Modal.Body>
        </Modal>

        <Modal show={showCashModal} onHide={handleCloseCashModal}>
          <Modal.Header closeButton>
            {t('cashbox:total_cash.title')}
          </Modal.Header>
          <Modal.Body>
            {handleCloseCashModal && <HandCash amountMax={cashbox.total.totalCash} />}
          </Modal.Body>
        </Modal>

        <Modal show={showSalaryModal} onHide={handleCloseSalaryModal}>
          <Modal.Header closeButton>
            {t('cashbox:total_salary.title')}
          </Modal.Header>
          <Modal.Body>
            <TakeSalary amountMax={cashbox.total.totalSalary} onApply={loadCashbox}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Cashbox;