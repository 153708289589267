import ClassHelper from './ClassHelper';

class FreeService extends ClassHelper {
  constructor({ title, status, deletedAt } = {}) {
    super(...arguments);
    this.title = title;
    this.status = status; // Status
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default FreeService;