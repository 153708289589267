import { useState, useCallback } from 'react';

const useWebsocket = ({ ignoreSessionError = false } = {}) => {
  const [isLoading, setIsLoading] = useState(false);

  const callApi = useCallback(async (api, params) => {
    setIsLoading(false);
    return { error: 'Not implemented' };
  }, []);

  return {
    isLoading,
    callApi,
  };
}

export default useWebsocket;