import ClassHelper from './ClassHelper';

class ExcursionSeo extends ClassHelper {
  constructor({ _id = null, id = null, title, description, keywords } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.description = description;
    this.keywords = keywords;
  }
}

export default ExcursionSeo;