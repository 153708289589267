import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

export const LINE_MODE = {
  ONE: 'one',
  TWO: 'two'
};

const Row = ({ className = '', children }) => {
  return (
    <div className={`info-row ${className}`}>{children}</div>
  );
}

Row.propTypes = {
  className: PropTypes.string
};

export default Row;