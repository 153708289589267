import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const CURRENCY = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
};

export const CURRENCY_SYMBOL = {
  RUB: String.fromCharCode(8381),
  EUR: String.fromCharCode(8364),
  USD: String.fromCharCode(36)
};

const Price = ({ value = 0, currency = CURRENCY.RUB, className = '', delim = ' ', onClick }) => {
  const symbol = CURRENCY_SYMBOL[currency.toUpperCase()] || '';

  const numberWithSpaces = () => {
    let parts = (typeof value === 'number' ? value : 0 ).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delim);
    return parts.join(".");
  }

  return (
    <span className={`currency ${className}`} onClick={onClick}>{numberWithSpaces()}{symbol}</span>
  );
};

Price.propTypes = {
  className: PropTypes.string,
  delim: PropTypes.string,
  value: PropTypes.number.isRequired,
  currency: PropTypes.string,
  onClick: PropTypes.func
};

const Input = ({ className = '', currency = CURRENCY.RUB, min, max, value, onInput, onChange }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  return (
    <div className="input-group">
      <input type="number" ref={inputRef} className={`form-control ${className}`} min={min} max={max} onInput={(e) => onInput && onInput(e.target.value)} onChange={(e) => onChange && onChange(e.target.value)} defaultValue={value} />
      <span className="input-group-text">{CURRENCY_SYMBOL[currency.toUpperCase()]}</span>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  currency: PropTypes.string
};

Price.Input = Input;

const Text = ({ value = 0, currency = CURRENCY.RUB, delim = ' ' }) => {
  const symbol = CURRENCY_SYMBOL[currency.toUpperCase()] || '';

  const numberWithSpaces = () => {
    let parts = (typeof value === 'number' ? value : 0 ).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delim);
    return parts.join(".");
  }

  return `${numberWithSpaces()}${symbol}`;
};

Text.propTypes = {
  delim: PropTypes.string,
  value: PropTypes.number.isRequired,
  currency: PropTypes.string
};

Price.Text = Text

export default Price;