import Logger from '../utils/logger';
import { getStorageValue } from './useLocalStorage';
import { STORAGE } from '../defs/storage';
import User from '../models/User';
import { getAPI } from '../server/api';
import Fixture from '../fixtures/Fixture';
import { callFixture } from '../fixtures/Fixture';
import { APICode } from '../models/types';

const useFixtures = (setIsLoading = () => {}) => {
  const logger = new Logger({ name: 'useFixtures', showDate: false, showFile: false });

  const callApi = async (api, params) => {
    logger.info('Fixtures:', api);
    if (!api || typeof api !== 'object') {
      return { error: APICode.UNKNOWN_REQUEST };
    }

    setIsLoading(true);

    let response = null;
    if (api.auth) {
      let authInfo = getStorageValue(STORAGE.AUTH, null, User);
      if (!authInfo || !authInfo.profile || !authInfo.profile.id) {
        setIsLoading(false);
        return { error: APICode.UNAUTHORIZED }
      }
    }

    let url = api.url;
    for (const param in api.uriVars) {
      let key = param.slice(1);
      const re = new RegExp(`${param}\\b`, 'gi');
      url = url.replace(re, params[key] || '');
    }

    const [moduleName, methodName] = getAPI(url);
    if (moduleName && methodName) {
      response = callFixture(Fixture[moduleName][methodName]['success'], params, Fixture.get);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return { error: APICode.UNKNOWN_RESPONSE };
    }

    logger.debug('Response:', response);
    if (response) {
      return response;
    } else {
      return { error: APICode.UNKNOWN_RESPONSE };
    }
  };

  return {
    callApi,
  };
}

export default useFixtures;