import { useEffect } from 'react';
import PropTypes from 'prop-types';

const StepPrint = () => {
  useEffect(() => {
  }, []);

  return (
    <>
      <div>
      </div>
    </>
  )
}

StepPrint.propTypes = {
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepPrint;