import ClassHelper from './ClassHelper';
import Account from './Account';
import Location from './Location';

class ObjectClass extends ClassHelper {
  constructor({ _id = null, id = null, account, inn, title, address, location, status, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.inn = inn;
    this.title = title;
    this.address = address;
    this.location = this.obj(location, Location);
    this.status = status; // ModerationStatus
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default ObjectClass;