import ClassHelper from './ClassHelper';

class Passport extends ClassHelper {
  constructor({ _id = null, id = null, number, fullName } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.number = number;
    this.fullName = fullName;
  }
}

export default Passport;