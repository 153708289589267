import ClassHelper from './ClassHelper';
import Limit from './Limit';
import ScheduleTransport from './ScheduleTransport';
import Subdivision from './Subdivision';

class ScheduleSubdivision extends ClassHelper {
  constructor({ _id = null, id = null, subdivision, limit, transports = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.subdivision = this.ref(subdivision, Subdivision);
    this.limit = this.obj(limit, Limit);
    this.transports = this.array(transports, ScheduleTransport);
  }
}

export default ScheduleSubdivision;