import React from 'react';
import PropTypes from 'prop-types';

export const STEPPER_ITEM_STATUS = {
  COMPLETED: 'completed',
  ACTIVE: 'active',
  WAIT: ''
};


class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = typeof props.onSelect === 'function' ? props.onSelect : () => {};
    this.onComplete = typeof props.onComplete === 'function' ? props.onComplete : () => {};
    this.onError = typeof props.onError === 'function' ? props.onError : () => {};

    this.props.steps.forEach((step, i) => {
      if (step.id === undefined) {
        step.id = `step_${i + 1}`;
      }
      step.status = i === 0 ? STEPPER_ITEM_STATUS.ACTIVE : STEPPER_ITEM_STATUS.WAIT;
    });
  }

  componentDidMount() {
    window.addEventListener('hashchange', () => this.handleHashChange(), false);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', () => this.handleHashChange(), false);
  }

  handleHashChange() {
    let id = window.location.hash.slice(1);
    let stepIdx = this.props.steps.findIndex((step) => step.id === id);
    this.goToStep(stepIdx);
  }

  goToStep(idx) {
    if (this.props.steps.length > idx && idx >= 0) {
      this.setState({ activeStep: idx });
    }
  }

  nextStep() {
    this.props.steps[this.props.activeStep].status = STEPPER_ITEM_STATUS.COMPLETED;

    let stepIdx = Math.min(this.props.activeStep + 1, Math.max(this.props.steps.length - 1, 0));

    let step = this.getStep(stepIdx);
    if (step) {
      window.location.hash = step.id;
    }

    this.setState({ activeStep: stepIdx });
  }

  previousStep() {
    let stepIdx = Math.max(this.props.activeStep - 1, 0);

    let step = this.getStep(stepIdx);
    if (step) {
      window.location.hash = step.id;
    }

    this.setState({ activeStep: stepIdx });
  }

  getStep(idx) {
    return this.props.steps.length > idx && idx >= 0 ? this.props.steps[idx] : null;
  }

  getCurrentStep() {
    return this.props.steps.length > 0 ? this.props.steps[this.props.activeStep] : null;
  }

  getCurrentStepIdx() {
    return this.props.activeStep;
  }

  isCurrent(idx) {
    return this.props.activeStep === idx;
  }

  getTitle() {
    const step = this.getCurrentStep();
    return step ? step.title : '';
  }

  getStatus(idx) {
    return this.props.steps[idx].status;
  }

  completeStep(idx) {
    this.props.steps[idx].status = STEPPER_ITEM_STATUS.COMPLETED;
    this.setState({ activeStep: idx });
  }

  handleSelectItem(idx) {
    const step = this.getStep(idx);
    if (step && (this.props.steps[idx].status === STEPPER_ITEM_STATUS.COMPLETED)) {
      this.setState({ activeStep: idx });
    }

    if (this.props.steps[idx].status !== STEPPER_ITEM_STATUS.WAIT) {
      this.onSelect(idx);
    }
  }

  render() {
    return (
      <>
        <div className="stepper-current-name">{this.getTitle()}</div>
        <div className="stepper-wrapper">
          {this.props.steps.map((step, i) => (
            <Stepper.Item key={i} onClick={() => this.handleSelectItem(i)} status={this.getStatus(i)} current={this.props.activeStep === i}>
              <Stepper.Counter/>
            </Stepper.Item>
          ))}
        </div>
      </>
    );
  }
}

Stepper.propTypes = {
  steps: PropTypes.array,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func
};

const Item = ({ onClick, status = STEPPER_ITEM_STATUS.WAIT, current = false, children }) => {
  return (
    <div onClick={(onClick)} className={('stepper-item ' + status + (current ? ' current' : ''))}>
      {children}
    </div>
  );
};

Stepper.Item = Item;

Stepper.Item.propTypes = {
  status: PropTypes.string,
  current: PropTypes.bool,
  onClick: PropTypes.func
};

const Counter = ({ children }) => {
  return (
    <div className="step-counter">
      {children}
    </div>
  );
};

Stepper.Counter = Counter;

const Name = ({ children }) => {
  return (
    <div className="step-name">
      {children}
    </div>
  );
};

Stepper.Name = Name;

class Step
{
  status = STEPPER_ITEM_STATUS.WAIT;

  constructor({ id, title, onSelect, onComplete, onError, validate }) {
    this.id = id;
    this.title = title;
    this.onSelect = typeof onSelect === 'function' ? onSelect : () => {};
    this.onComplete = typeof onComplete === 'function' ? onComplete : () => {};
    this.onError = typeof onError === 'function' ? onError : () => {};
    this.validate = typeof validate === 'function' ? validate : () => {};
  }
};

Step.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activeStep: PropTypes.number,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  validate: PropTypes.func
};

export { Step };

export default Stepper;