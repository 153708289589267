import { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Modal from '../../components/Modal';
import NavBar from '../../components/NavBar';
import { ROUTES } from '../../defs/routes';
import SelectPassengers from '../new_order/select_passengers';
import { UserContext } from '../../store/UserProvider';
import ExcursionInfo from '../new_order/excursion_info';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Schedule from '../../models/Schedule';
import API from '../../server/api';
import DateFormat from '../../components/DateFormat';
import ReportParams from '../../models/ReportParams';
import { getDatePeriodArray } from '../../components/DatePeriod';
import Table from '../../components/Table';
import Price, { CURRENCY } from '../../components/Price';
import log from '../../utils/logger';
import RepositoryModel from '../../components/RepositoryModel';
import Question from '../../components/icons/Question';
import Input from '../../components/Input';
import Search from '../../components/icons/Search';
import { PriceAppliesTo } from '../../models/types';

dayjs.extend(utc);
dayjs.extend(timezone);

const Excursions = () => {
  const navigate = useNavigate();
  const {t} = useTranslation(['common', 'excursion', 'new_order']);

  const [days, setDays] = useState([]);
  const [date, setDate] = useState(null);
  const [excursion, setExcursion] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [price, setPrice] = useState(null);
  const [query, setQuery] = useState('');
  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [showSelectPassengers, setShowSelectPassengers] = useState(false);
  const [showExcursionInfo, setShowExcursionInfo] = useState(false);

  const handleSearch = useCallback((query = query, day = date) => {
    let filters = {
      date: day || DateFormat.Request({ date: new Date() }),
      timezone: dayjs.tz.guess(),
      subdivision: userInfo.getSession().subdivision
    };

    repository.list(API.excursion.excursions, filters, Schedule).then((schedules) => {
      // setSchedules(schedules);
      // schedules.excursions.excursion.title
      let _schedules = [];
      if (query !== '') {
        schedules.forEach((schedule) => {
          let _excursions = schedule.excursions.filter((excursion) => excursion.excursion.title.indexOf(query) >= 0);
          if (_excursions.length > 0) {
            schedule.excursions = _excursions;
            _schedules.push(schedule);
          }
        });

        setSchedules(_schedules);
      } else {
        setSchedules(schedules);
      }
    }).catch((e) => {
      log.error(e);
      setSchedules([]);
    });

    repository.list(API.price.prices, { tab: PriceAppliesTo.EXCURSION }).then((prices) => {
    }).catch((e) => {
      log.error(e);
    });
  }, [query, date]);

  const handleCloseSelectPassengers = useCallback(() => {
    userInfo.resetOrderSession();
    setShowSelectPassengers(false);
  }, []);

  const handleOpenSelectPassengers = useCallback((schedule, excursion, subdivision, prices, price) => {
    setShowSelectPassengers(true);
    userInfo.setPrices(prices);
    userInfo.resetOrderSession();
    userInfo.setOrderSession({
      date,
      subdivision: subdivision.id,
      time: schedule.getTime(),
      schedule: schedule.id,
      excursion: excursion.id
    });
    setPrice(price);
    setSchedule(schedule);
  }, [date]);

  const handleNextSelectPassengers = useCallback(({passengers, wishes}) => {
    // log.print(passengers)
    // log.print(wishes);

//  amount = 3000
//  reward
//  id = 66a647bf4d393d3d6da48739
//  value = 20
//  type = percent
//  minAge = 3
//  maxAge = 6
//  noSeat = false
//  partialValue = 0

    userInfo.setOrderSession({
      passengers,
      wishes
    });
    log.print(userInfo.getOrderSession());
    navigate(ROUTES.NEW_ORDER);
  }, []);

  const handleSelectScheduleDate = useCallback((day) => {
    let data = {
      date: DateFormat.Request({ date: day }),
      subdivision: userInfo.getSession().subdivision
    };
    setDate(data.date);

    userInfo.setOrderSession(data);

    handleSearch(query, data.date);
  }, []);

  const handleCloseExcursionInfo = useCallback(() => {
    setShowExcursionInfo(false);
    setExcursion(null);
  }, []);

  const handleOpenExcursionInfo = useCallback((excursion) => {
    setExcursion(excursion);
    loadExcursion(excursion.id);
    setShowExcursionInfo(true);
  }, []);

  const loadExcursion = (id) => {
    repository.request(API.excursion.excursion, { id }).then((excursion) => {
    }).catch((e) => {
    });
  };


  const fetchSchedules = () => {
    let newDays = getDatePeriodArray(new Date(), 14);

    repository.report(API.schedule.report, new ReportParams({ filters: { 'fromDate': newDays[0].str, 'toDate': newDays[newDays.length - 1].str } })).then((report) => {
      for (let i = 0; i < report.rows.length; ++i) {
        let record = report.rows[i];

        for (let date in record.schedules) {
          let _day = newDays.find((day) => day.str === date);
          if (_day) {
            _day.count = _day.count ? _day.count + 1 : 1;
          }
        }
      }
      setDays(newDays);
    }).catch((e) => {
      setDays(newDays);
    });

    setDate(DateFormat.Request({ date: newDays[0].date.toDate() }));
    
    handleSearch(query, newDays[0].str);
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  useEffect(() => {
    handleSearch(query, date);
  }, [query]);

  return (
    <div className="trip-app">
      <NavBar title={t('excursion:excursions.title')} />
      <div className="trip-app-body">
        <Input value={query} icons={<Search onClick={() => handleSearch(query, date)} />} onChange={setQuery}/>
        <div className="calendar-day-list cards">
          {days.map((day) => (
            <div key={day.id} className={'card calendar-day' + (day.count ? '' : ' empty-excursion') + (DateFormat.Request({ date }) === day.str ? ' selected' : '')} onClick={() => handleSelectScheduleDate(day.date.toDate())}>
              <div className="day-date">{`${day.date.toDate().getDate()}`}</div>
              <div className="day-name">{t(`common:days.short.day${day.date.toDate().getDay()}`)}</div>
            </div>
          ))}
        </div>
        <div className="excursion-list">
          {schedules.map((schedule) => (
            schedule.excursions.map((excursion) => (
              excursion.excursion && excursion.subdivisions.find((subd) => subd.subdivision.id === userInfo.getSession().subdivision) && excursion.excursion.subdivisions.map((subdivision) => (
                <div key={`${schedule.id}_${excursion.id}_${subdivision.id}`} className="card excursion-card" style={{'backgroundImage': `url(${excursion.excursion.cover1 ? excursion.excursion.cover1.getUrl(400) : ''})`}}>
                  <div className="card-header">
                    <h4 className="card-header-name">{excursion.excursion.title}</h4>
                    <div className="card-header-info">
                      <Question className="rounded" onClick={() => {handleOpenExcursionInfo(excursion.excursion);}} />
                    </div>
                    <div className="excursion-duration">
                      {t('common:duration.day.day', {count: subdivision.getDuration().days})}  {t('common:duration.hour.hour', {count: subdivision.getDuration().hours})}
                    </div>
                  </div>
                  <div className="collapse show" id="mycard-collapse">
                    <div className="card-body">
                      <div className="card-subdivision">
                        <div className="subdivision-time">
                          {schedule.getTime()}
                        </div>
                        <div className="subdivision-places">
                          {t('common:place.place', {count: schedule.getSeats() || 0})}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer excursion-tickets">
                      <Table cols={3}>
                        <Table.Row>
                          {subdivision.prices && subdivision.prices.filter((price) => !price.price.deletedAt).length === 0 && (
                            <div className="excursion-ticket-type">{t('excursion:excursions.no_price')}</div>
                          )}
                          {subdivision.prices && subdivision.prices.filter((price) => !price.price.deletedAt).map((price, i) => (
                            <Table.Cell key={i} className="excursion-ticket" onClick={() => { handleOpenSelectPassengers(schedule, excursion.excursion, subdivision, subdivision.prices, price); }}>
                              <div className="excursion-ticket-type">
                                {price.price.title}
                              </div>
                              <div className="excursion-ticket-price"><Price currency={CURRENCY.RUB} value={price.amount} /></div>
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Table>
                    </div>
                  </div>
                </div>
              ))
            ))
          ))}
        </div>
      </div>

      <Modal show={showSelectPassengers} onHide={handleCloseSelectPassengers}>
        <Modal.Header closeButton>
          {t('new_order:select_passengers.title')}
        </Modal.Header>
        <Modal.Body>
          <SelectPassengers
            prices={userInfo.getPrices()}
            limits={{seats: (schedule ? schedule.limit.value - schedule.limit.cntSeats : 0)}}
            schedule={schedule}
            passengers={[{excursion_price: price && price.id, price: price && price.price.id, count: 1, passports: [], ages: []}]}
            wishes={[]}
            onApply={handleNextSelectPassengers}
            onCancel={handleCloseSelectPassengers}
          />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={excursion && showExcursionInfo} onHide={handleCloseExcursionInfo}>
        <Modal.Header closeButton>
          {excursion && excursion.title}
        </Modal.Header>
        <Modal.Body>
          <ExcursionInfo excursion={excursion} onApply={handleCloseExcursionInfo} onClose={handleCloseExcursionInfo} />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Excursions;