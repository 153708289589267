import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import QR from 'react-qr-code';
import QrScanner from 'qr-scanner';
import log from '../utils/logger';

const QRCode = ({ value, className = '' }) => {
  return (
    <div className={`qr-code ${className}`}>
      <QR size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={value} viewBox={`0 0 256 256`} />
    </div>
  );
};

QRCode.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
};

const Scanner = ({ className = '', onResult }) => {
  const [qrScanner, setQrScanner] = useState(null);
  const [data, setData] = useState(null);
  const cameraRef = useRef();

  const updateFlashAvailability = () => {
    qrScanner && qrScanner.hasFlash().then(hasFlash => {
      log.debug('FLASH', hasFlash);
    });
  };

  useEffect(() => {
    if (cameraRef.current && !qrScanner) {
      const qrScanner = new QrScanner(
        cameraRef.current,
        (result) => {
          if (result.data && data !== result.data) {
            setData(result.data);
            onResult && onResult(result.data, null, qrScanner);
          }
        },
        {
          preferredCamera: QrScanner.DeviceId, //QrScanner.FacingMode | QrScanner.DeviceId,
          onDecodeError: () => {},
          returnDetailedScanResult: true,
        }
      );

      qrScanner.start().then(() => {
        updateFlashAvailability();
        // QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
        //   console.log('CAMERA:', camera);
        // }));
      }).catch((e) => {
        log.error(e);
      });

      setQrScanner(qrScanner);
    }
  }, [cameraRef, cameraRef.current]);

  return (
    <section className={className}>
      <div className="qr-reader">
        <video ref={cameraRef}></video>
        <div className="scan-region-highlight">
          <svg className="scan-region-highlight-svg" viewBox="0 0 238 238" preserveAspectRatio="none">
            <path d="M31 2H10a8 8 0 0 0-8 8v21M207 2h21a8 8 0 0 1 8 8v21m0 176v21a8 8 0 0 1-8 8h-21m-176 0H10a8 8 0 0 1-8-8v-21"></path>
          </svg>
          <svg className="code-outline-highlight" preserveAspectRatio="none">
            <polygon></polygon>
          </svg>
        </div>
      </div>
    </section>
  )
};

Scanner.prototype = {
  className: PropTypes.string,
  onResult: PropTypes.func
};

QRCode.Scanner = Scanner;

export default QRCode;
