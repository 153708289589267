import { useEffect, useState, useContext, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
// import PhoneInput from 'react-phone-number-input/input'
import { DynamicPriceType, Status } from '../../models/types';
import { UserContext } from '../../store/UserProvider';
import API from '../../server/api';
import ReportParams from '../../models/ReportParams';
import Discount from '../../models/Discount';
import User from '../../models/User';
import Field from '../../components/Field';
import log from '../../utils/logger';
import Input from '../../components/Input';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import Select from '../../components/Select';
import Add from '../../components/icons/Add';
import Delete from '../../components/icons/Delete';
import { DayPicker } from 'react-day-picker';
import DateFormat from '../../components/DateFormat';
import ErrorHint from '../../components/ErrorHint';
import Price, { CURRENCY } from '../../components/Price';
import Phone from '../../components/Phone';

const phoneRegex = /\+\d{11}/;

const StepClientInfo = ({ onChange, onComplete, onError }) => {
  const {t} = useTranslation(['common', 'new_order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [discounts, setDiscounts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [phones, setPhones] = useState([]);

  const [user, setUser] = useState(null);

  const [clientPhone, setClientPhone] = useState('');
  const [clientContactPhones, setClientContactPhones] = useState([]);
  const [clientName, setClientName] = useState('');
  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState('');
  const [clientMiddleName, setClientMiddleName] = useState('');
  const [clientBirthday, setClientBirthday] = useState('');
  const [clientDiscounts, setClientDiscounts] = useState(null);

  const handleAddPhone = useCallback(() => {
    setClientContactPhones([ ...clientContactPhones, '' ]);
  }, [clientContactPhones]);

  const handleDeletePhone = useCallback((idx) => {
    clientContactPhones.splice(idx, 1);
    setClientContactPhones([ ...clientContactPhones ]);
  }, [clientContactPhones]);


  useEffect(() => {
    repository.report(API.discount.discounts, new ReportParams(), Discount).then((report) => {
      setDiscounts(report.rows);
      let discount = report.rows.find((discount) => discount.id === userInfo.getOrderSession().discount);
      if (discount) {
        setClientDiscounts({
          id: discount.id,
          type: discount.discount.type,
          value: discount.discount.value
        });
      } else {
        setClientDiscounts(null)
      }
    }).catch((e) => {
      setDiscounts([]);
      setClientDiscounts(null);
    });

    setClientPhone(userInfo.getOrderSession().phone || '');
    setClientContactPhones(userInfo.getOrderSession().contact_phones || []);
    setClientName(userInfo.getOrderSession().name || '');
    setClientFirstName(userInfo.getOrderSession().first_name || '');
    setClientLastName(userInfo.getOrderSession().last_name || '');
    setClientMiddleName(userInfo.getOrderSession().middle_name || '');
    setClientBirthday(userInfo.getOrderSession().birthday || '');
    setClientName(userInfo.getOrderSession().name || '');
    setPhones((userInfo.getOrderSession().contact_phones || []).map((phone) => ({ value: phone, error: null })));
    setClientName(`${userInfo.getOrderSession().first_name || ''} ${userInfo.getOrderSession().last_name || ''} ${userInfo.getOrderSession().middle_name || ''}`.trim());

    loadUserByPhone(userInfo.getOrderSession().phone);
  }, []);

  useEffect(() => {
    const info = {
      phone: clientPhone,
      contact_phones: clientContactPhones,
      name: clientName,
      first_name: clientFirstName,
      last_name: clientLastName,
      middle_name: clientMiddleName,
      birthday: clientBirthday,
      discount: clientDiscounts,
      user: user
    };

    orderInfo.setClientInfo(info);
    userInfo.setOrderSession(info);

    if (!user) {
      validate(info);
    }
  }, [clientPhone, clientContactPhones, clientName, clientFirstName, clientLastName, clientMiddleName, clientBirthday, clientDiscounts, user]);

  const loadUserByPhone = async (phone) => {
    setClientPhone(phone);

    if (phoneRegex.test(phone)) {
      repository.report(API.user.users, new ReportParams({ filters: { 'phone': phone } }), User).then((report) => {
        if (report.rows.length === 1) {
          setUser(report.rows[0].id);
          setClientName(report.rows[0].profile.fullName);
        } else {
          setUser(null);
          setClientName('');
        }
        setClientPhone(phone);
      }).catch((e) => {
        log.error('loadUserByPhone', e);
      });
    }
  };

  const handlePhoneInput = useCallback((val) => {
    loadUserByPhone(val);
  }, []);

  const handleContactPhoneInput = useCallback((val, i) => {
    let phones = [...clientContactPhones];
    phones[i] = { value: val, error: null };
    setClientContactPhones([ ...phones ]);
    let addPhoneValue = phones.filter((phone) => !phone.error).map((phone) => phone.value);
    setClientContactPhones(addPhoneValue);
  }, [clientContactPhones]);

  const handleFirstNameInput = useCallback((val) => {
    setClientFirstName(val);

    setClientName(`${val} ${clientLastName} ${clientMiddleName}`.trim());
  }, [clientFirstName, clientLastName, clientMiddleName, clientName]);

  const handleLastNameInput = useCallback((val) => {
    setClientLastName(val);

    setClientName(`${clientFirstName} ${val} ${clientMiddleName}`.trim());
  }, [clientFirstName, clientLastName, clientMiddleName, clientName]);

  const handleMiddleNameInput = useCallback((val) => {
    setClientMiddleName(val);

    setClientName(`${clientFirstName} ${clientLastName} ${val}`.trim());
  }, [clientFirstName, clientLastName, clientMiddleName, clientName]);

  const handleBirthdayInput = useCallback((val) => {
    setClientBirthday(DateFormat.Request({ date: val }));
  }, [clientBirthday]);

  const handleDiscountSelect = useCallback((val) => {
    let discount = discounts.find((discount) => discount.id === val);
    if (discount) {
      setClientDiscounts({
        id: discount.id,
        type: discount.discount.type,
        value: discount.discount.value
      });
    } else {
      setClientDiscounts(null);
    }
  }, [clientDiscounts, discounts]);

  const handleCloseError = (uid) => {
    orderInfo.clearError('client_info', uid);
    if (uid) {
      let idx = errors.findIndex(error => error.uid === uid);
      if (idx >= 0) {
        errors.splice(idx, 1);
      }
    } else {
      errors = [];
    }
    setErrors(errors);
  };

  const validate = async (info) => {
    let errors = await orderInfo.validate(STEPS.client_info, info);
    setErrors(errors);
  };

  return (
    <>
      <div>
        {errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}
        <Field title={t('new_order:client_info.phone')}>
          <div className="input-group">
            <Phone.Input className="form-control phone-primary" value={clientPhone} onChange={handlePhoneInput}/>
            {clientPhone && (
              <span className="input-group-text">
                <Add onClick={handleAddPhone} />
              </span>
            )}
          </div>
        </Field>
        {phones.length > 0 && (
          <Field title={t('new_order:client_info.phone_additional')}>
            {phones.map((phone, i) => (
              <Fragment key={i}>
                <div className="input-group">
                  <Phone.Input className="form-control phone-additional" data-idx={i} value={phone.value} name="add-phone" international onChange={(val) => {handleContactPhoneInput(val, i);}}/>
                  <span className="input-group-text">
                    <Delete onClick={() => handleDeletePhone(i)} />
                  </span>
                </div>
                {phone.error && (
                  <ErrorHint error={phone.error} />
                )}
              </Fragment>
            ))}
          </Field>
        )}
        {clientPhone && (
          <>
            {user ? (
              <Field title={t('new_order:client_info.person_name')}>
                <Input value={clientName} readOnly name="person_name"/>
              </Field>
            ) : (
              <>
                <Field title={t('new_order:client_info.first_name')}>
                  <Input value={clientFirstName} name="first_name" onChange={handleFirstNameInput}/>
                </Field>
                <Field title={t('new_order:client_info.last_name')}>
                  <Input value={clientLastName} name="last_name" onChange={handleLastNameInput}/>
                </Field>
                <Field title={t('new_order:client_info.middle_name')}>
                  <Input value={clientMiddleName} name="middlw_name" onChange={handleMiddleNameInput}/>
                </Field>
                <Field title={t('new_order:client_info.birthday')}>
                  <SelectDate mode={DAY_PICKER_MODE.SINGLE} selected={clientBirthday} onChange={handleBirthdayInput} readOnly={!!user} fromYear={1900} toYear={new Date().getFullYear()} />
                </Field>
              </>
            )}
          </>
        )}
        <Field title={t('new_order:client_info.discounts')}>
          <Select
            value={clientDiscounts ? clientDiscounts.id : -1}
            empty={{ value: -1, text: t('new_order:client_info.no_discount')}}
            options={discounts.filter((discount) => discount.status === Status.ACTIVE).map((discount) => (
              {
                value: discount.id,
                text: `${discount.title} - ${discount.discount.type === DynamicPriceType.PERCENT ? `${discount.discount.value}%` : Price.Text({ currency: CURRENCY.RUB, value: discount.discount.value })}`
              }
            ))}
            onSelect={handleDiscountSelect}
          />
        </Field>
      </div>
    </>
  )
}

StepClientInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepClientInfo;