import React from 'react';
import PropTypes from 'prop-types';

const InfoDescription = ({ className = '', description = '', delim = false, children }) => {
  return (
    <div className={`info-field ${delim ? 'delim' : ''} ${className}`}>
      <div className="info-field-data">{children}</div>
      {description && (
        <div className="info-field-description">{description}</div>
      )}
    </div>
  );
}

InfoDescription.propTypes = {
  className: PropTypes.string,
  delim: PropTypes.bool,
  description: PropTypes.any
};

export default InfoDescription;