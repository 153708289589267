/* eslint-disable no-template-curly-in-string */

const FixtureUser = {
  whoami: {
    success: '{\n' +
'      "profile": {\n' +
'          "firstName": "${store.user.profile.firstName}",\n' +
'          "lastName": "${store.user.profile.lastName}",\n' +
'          "middleName": "${store.user.profile.middleName}",\n' +
'          "birthday": "${store.user.profile.birthday}",\n' +
'          "approved": ${store.user.profile.approved},\n' +
'          "_id": "${store.user.profile.id}"\n' +
'      },\n' +
'      "phone": "${store.user.phone}",\n' +
'      "language": "${store.user.language}",\n' +
'      "accounts": [\n' +
'        ${(() => {\n' +
'          let str = "";\n' +
'          for (const account of store.accounts) {\n' +
'            str += `${str.length ? "," : ""}{\n' +
'              "account": "${account.account}",\n' +
'              "accountType": "${account.accountType}",\n' +
'              "roles": [\n' +
'                ${(() => {\n' +
'                  let str = "";\n' +
'                  for (const role of account.roles) {\n' +
'                    str += `${str.length ? "," : ""}"${role}"`\n' +
'                  }\n' +
'                  return str;\n' +
'                })()}\n' +
'              ],\n' +
'              "status": "${account.status}",\n' +
'              "_id": "${account.id}",\n' +
'              "accountTitle": "${account.accountTitle}",\n' +
'              "accountUrl": "${account.accountUrl}"\n' +
'            }`;\n' +
'          }\n' +
'          return str;\n' +
'        })()}\n' +
'      ]\n' +
'    }'
  }
};

export default FixtureUser;