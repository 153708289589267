import React from 'react';
import PropTypes from 'prop-types';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: !!props.checked };
  }

  render() {
    return (
      <div className={`list ${this.props.className || ''}`} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

List.propTypes = {
  className: PropTypes.string
};

const Row = ({ className = '', children }) => {
  return (
    <div className={`list-row ${className}`}>
      {children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string
};

List.Row = Row;

const Cell = ({ className = '', children }) => {
  return (
    <div className={`list-cell ${className}`}>
      {children}
    </div>
  );
};

Cell.propTypes = {
  className: PropTypes.string
};

List.Cell = Cell;

export default List;