import ClassHelper from './ClassHelper';
import Account from './Account';
import User from './User';

class Collection extends ClassHelper {
  constructor({ _id = null, id = null, account, user, collector, date, amount } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.user = this.ref(user, User);
    this.collector = this.ref(collector, User);
    this.date = this.obj(date, Date);
    this.amount = amount;
  }
}

export default Collection;