import ClassHelper from './ClassHelper';
import AccountObject from './AccountObject';
import { City } from './GEO';
import ExcursionPlace from './ExcursionPlace';
import ExcursionPrice from './ExcursionPrice';
import Subdivision from './Subdivision';

class ExcursionSubdivision extends ClassHelper {
  constructor({ _id = null, id = null, enabled, subdivision, city, duration, places = [], prices = [], partialPaymentAllowed, noDiscount, objects = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.enabled = enabled;
    this.subdivision = this.ref(subdivision, Subdivision);
    this.city = this.ref(city, City);
    this.duration = duration;
    this.places = this.array(places, ExcursionPlace, true);
    this.prices = this.array(prices, ExcursionPrice, true);
    this.partialPaymentAllowed = partialPaymentAllowed;
    this.noDiscount = noDiscount;
    this.objects = this.array(objects, AccountObject, true);
  }

  getDuration() {
    let durationObj = {
      days: 0,
      hours: 0,
      minutes: 0
    };
    if (this.duration) {
      let d = this.duration;
      durationObj.days = Math.trunc(d / 86400);
      d %= 24 * 3600;
      durationObj.hours = Math.trunc(d / 3600);
      d %= 3600;
      durationObj.minutes = Math.trunc(d / 60);
    }
    return durationObj;
  }
}

export default ExcursionSubdivision;