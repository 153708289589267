import { useTranslation } from 'react-i18next';
import NavBar from '../../components/NavBar';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import DatePeriod from '../../components/DatePeriod';
import Modal from '../../components/Modal';
import SelectDate from '../cashbox/select_date';
import ReportParams from '../../models/ReportParams';
import Ticket from '../order/ticket';
import OrderDetails from '../../models/OrderDetails';
import log from '../../utils/logger';
import List from '../../components/List';

const Tickets = () => {
  const {t} = useTranslation(['common', 'ticket']);

  const repository = useContext(RepositoryContext);

  const [tickets, setTickets] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showRangeModal, setShowRangeModal] = useState(false);

  const handleCloseRangeModal = useCallback(() => {
    setShowRangeModal(false);
  }, []);

  const handleApplyRangeModal = useCallback((range) => {
    setShowRangeModal(false);
    setStartDate(range.from);
    setEndDate(range.to || range.from);
  }, []);

  const loadTickets = useCallback(async () => {
    try {
      let _tickets = await repository.report(API.user.tickets, new ReportParams(), OrderDetails);
      // {
      //   fromDate: DateFormat.Request({ date: startDate }),
      //   toDate: DateFormat.Request({ date: endDate })
      // }
      setTickets(_tickets.rows);
    } catch(e) {
      log.error(e);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    loadTickets();
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, []);

  return (
    <div className="trip-app">
      <NavBar title={t('ticket:ticket.title')} />
      <div className="trip-app-body">
        <div className="header-line">{t('ticket:ticket.period')}</div>
        <div className="input">
          <DatePeriod className="form-control" start={startDate} end={endDate} onClick={() => setShowRangeModal(true)}/>
        </div>
        <List>
          {tickets.map((ticket) => (
            <List.Row key={ticket.id} delim={true}>
              <Ticket
                order={ticket.id}
                excursion={ticket.excursion}
                date={ticket.date}
                schedule={ticket.schedule}
                seats={ticket.seats}
                transports={ticket.transports}
                paidAmount={ticket.paidAmount}
                paymentMethod={ticket.paymentMethod}
                status={ticket.status}
              />
            </List.Row>
          ))}
        </List>

        <Modal show={showRangeModal} onHide={handleCloseRangeModal}>
          <Modal.Header closeButton>
            {t('ticket:date_period.title')}
          </Modal.Header>
          <Modal.Body>
            <SelectDate start={startDate} end={endDate} onApply={handleApplyRangeModal} onCancel={handleCloseRangeModal}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Tickets;