import ClassHelper from './ClassHelper';
import Excursion from './Excursion';
import Limit from './Limit';
import ScheduleSubdivision from './ScheduleSubdivision';

class ScheduleExcursion extends ClassHelper {
  constructor({ _id = null, id = null, excursion, limit, subdivisions = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.excursion = this.ref(excursion, Excursion);
    this.limit = this.obj(limit, Limit);
    this.subdivisions = this.array(subdivisions, ScheduleSubdivision);
  }
}

export default ScheduleExcursion;