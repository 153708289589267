import ClassHelper from './ClassHelper';
import Account from './Account';
import Location from './Location';
import Subdivision from './Subdivision';

class Place extends ClassHelper {
  constructor({ _id = null, id = null, title, account, status, address, location, subdivision, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.account = this.ref(account, Account);
    this.status = status; // Status
    this.address = address;
    this.location = this.obj(location, Location);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Place;