import PropTypes from 'prop-types';

const Add = ({ className = '', onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 32 32" onClick={onClick}>
      <path d="M24,18h-6v6h-4v-6H8v-4h6V8h4v6h6V18z"/>
    </svg>
  );
}

Add.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Add;