import { v4 as uuidv4 } from 'uuid';

class Message {
  constructor({type, code, title, message = ''}) {
    this.uid = uuidv4().replace(/-/g, '');
    this.type = type;
    this.code = code;
    this.title = title;
    this.message = message;
  }
}

export default Message;