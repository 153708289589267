import ClassHelper from './ClassHelper';

class Moderation extends ClassHelper {
  constructor({ status, comment = null } = {}) {
    super(...arguments);
    this.status = status; // ModerationStatus
    this.comment = comment;
  }
}

export default Moderation;