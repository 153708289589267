import DiscountBase from './DiscountBase';
import Excursion from './Excursion';
import Schedule from './Schedule';

class Discount extends DiscountBase {
  constructor({ excursion, schedule } = {}) {
    super(...arguments);
    this.excursion = this.ref(excursion, Excursion);
    this.schedule = this.ref(schedule, Schedule);
  }
}

export default Discount;