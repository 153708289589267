import log from './logger';

export const getJSON = (str, defaultValue = null) => {
  let value = null;
  if (str === undefined || str === null) {
    value = defaultValue;
  } else if (typeof str === 'string') {
    try {
      value = JSON.parse(str);
    } catch (e) {
      log.debug('STR=', str);
      log.error(e);
      value = defaultValue;
    }
  } else {
    value = str;
  }

  return value;
}
