import ClassHelper from './ClassHelper';
import Contact from './Contact';
import Requisite from './Requisite';
import User from './User';

class Account extends ClassHelper {
  constructor({ _id = null, id = null, title, url, owner, type, status, requisite, percent, balance, siteUrl, description, logo, privateContact, publicContact, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.description = description;
    this.url = url;
    this.siteUrl = siteUrl;
    this.logo = logo;
    this.owner = this.ref(owner, User);
    this.type = type; // AccountType
    this.status = status; // AccountStatus
    this.requisite = this.obj(requisite, Requisite);
    this.privateContact = this.obj(privateContact, Contact);
    this.publicContact = this.obj(publicContact, Contact);
    this.percent = percent;
    this.balance = balance;
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Account;