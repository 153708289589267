import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderContext } from '../../store/OrderProvider';
import { DynamicPriceType } from '../../models/types';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import RepositoryModel from '../../components/RepositoryModel';
import DateFormat from '../../components/DateFormat';
import Field from '../../components/Field';

const ConfirmOrder = ({ onNext, onClose }) => {
  const {t} = useTranslation(['common', 'new_order']);

  const orderInfo = useContext(OrderContext);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <table>
          <tbody>
            <tr>
              <td>
                <InfoDescription description={t('new_order:new_order.in_check')}>
                  <Price currency={CURRENCY.RUB} value={orderInfo.getClientInfoAmount()} />
                </InfoDescription>
              </td>
              <td>
                <InfoDescription description={t('new_order:new_order.seats')}>
                  {orderInfo.getCommonInfo().passengers.reduce((count, passenger) => count + passenger.count, 0)}
                </InfoDescription>
              </td>
            </tr>
          </tbody>
        </table>
        <hr/>
        <Field title={t('new_order:confirm_order.trip')}>
          <RepositoryModel type="Excursion" uid={orderInfo.getCommonInfo().excursion} render={(model) => model.title} />
        </Field>
        <Field title={t('new_order:confirm_order.client')}>
          {orderInfo.getClientInfo().phone} - {orderInfo.getClientInfo().name}
        </Field>
        <Field title={t('new_order:confirm_order.passengers')}>
          {orderInfo.getCommonInfo().passengers.map((passenger) => (
            <span key={passenger.price}>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></span>
          ))}
        </Field>
        <Field title={t('new_order:confirm_order.place')}>
          <RepositoryModel type="Place" uid={orderInfo.getCommonInfo().place} render={(model) => model.title} />
        </Field>
        <Field title={t('new_order:confirm_order.date')}>
          <DateFormat.Day date={orderInfo.getCommonInfo().date}/>, {orderInfo.getCommonInfo().time}
        </Field>
        <Field title={t('new_order:confirm_order.comment')}>
          {orderInfo.getPaymentBooking().comment || '-'}
        </Field>
        <Field title={t('new_order:confirm_order.additional')}>
          <>
            <div className="toggle-list">
              {orderInfo.getCommonInfo().wishes.map((wish) => (
                t(`new_order:wishes.${wish}`)
              ))}
            </div>
            {orderInfo.getClientInfo().discount && (
              <div>
                <RepositoryModel type="Discount" uid={orderInfo.getClientInfo().discount} render={(model) => (
                  `${model.title} - ${model.discount.type === DynamicPriceType.PERCENT ? `${model.discount.value}%` : Price.Text({ currency: CURRENCY.RUB, value: model.discount.value })}`
                )} />
              </div>
            )}
          </>
        </Field>
      </div>
    </div>
  );
}

ConfirmOrder.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmOrder;