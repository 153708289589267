import React from 'react';
import PropTypes from 'prop-types';

export const INFO_VIEW_MODE = {
  ONE: 'one',
  TWO: 'two'
};

const Info = ({ className = '', title = '', mode = INFO_VIEW_MODE.TWO, children }) => {
  return (
    <div className={`info-field mode-${mode} ${className}`}>
      {(mode === INFO_VIEW_MODE.TWO) && (
        <>
          {title && (
            <div className="info-field-title">{title}</div>
          )}
          <div className="info-field-data">{children}</div>
        </>
      )}
      {(mode === INFO_VIEW_MODE.ONE) && (
        <>
          {title && (
            <span className="info-field-title">{title}</span>
          )}
          <span className="info-field-data">{children}</span>
        </>
      )}
    </div>
  );
}

Info.propTypes = {
  mode: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};

export default Info;