import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import { OrderContext } from '../../store/OrderProvider';
import { UserContext } from '../../store/UserProvider';
import { PaymentMethod } from '../../models/types';
import Field from '../../components/Field';

const StepPaymentBooking = ({ onChange }) => {
  const {t} = useTranslation(['common', 'new_order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);

  const [bookingPaymentMethod, setBookingPaymentMethod] = useState(PaymentMethod.CASH);
  const [bookingComment, setBookingComment] = useState();

  const handleCloseError = useCallback((uid) => {
    orderInfo.clearError('payment_booking', uid);
  }, []);

  useEffect(() => {
    setBookingPaymentMethod(userInfo.getOrderSession().payment_method || PaymentMethod.CASH);
    setBookingComment(userInfo.getOrderSession().comment || '');
  }, []);

  useEffect(() => {
    const info = {
      payment_method: bookingPaymentMethod,
      comment: bookingComment
    };

    orderInfo.setPaymentBooking(info);
    userInfo.setOrderSession(info);
  }, [bookingPaymentMethod, bookingComment]);

  return (
    <>
      <div>
        {orderInfo.getPaymentBooking().errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}
        <Field title={t('new_order:payment_booking.payment_method')}>
          {Object.values(PaymentMethod).map((method) => (
            <div key={method} className="payment-method" onClick={() => setBookingPaymentMethod(method)}>{t(`new_order:payment_booking.${method}`)}{bookingPaymentMethod === method && (<span className="payment-current">{t('new_order:payment_booking.current')}</span>)}</div>
          ))}
        </Field>
        <Field title={t('new_order:payment_booking.comment')}>
          <textarea rows="3" className="comment" onChange={(e) => setBookingComment(e.target.value)} value={bookingComment}/>
        </Field>
      </div>
    </>
  )
}

StepPaymentBooking.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepPaymentBooking;