import React, { useCallback, useEffect, useContext, useRef, useState } from 'react';
import { ReactComponent as MenuIcon } from '../img/menu.svg';
import BootstrapOffcanvas from 'react-bootstrap/Offcanvas';
import PropTypes from 'prop-types';
import { ROUTES } from '../defs/routes';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import Accounts from '../screen/account/accounts';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../store/UserProvider';
import DropRight from './icons/DropRight';
import PersonName, { PERSON_NAME_FORMAT } from './PersonName';
import Profile from './icons/Profile';
import Image from './Image';
import PrivatePage from './PrivatePage';

const NavBar = ({ title }) => {
  const [open, setOpen] = useState(false);
  const headerRef = useRef(null);
  const [showCompany, setShowCompany] = useState(false);

  const navigate = useNavigate();

  const {t} = useTranslation('common');

  const [user, setUser] = useState(null);
  const userInfo = useContext(UserContext);
  const userSession = userInfo.getSession();

  const userCompany = (user && user.getAccount(userSession.account)) || {};

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleCloseCompany = useCallback((needReload) => {
    setShowCompany(false);
    if (needReload) {
      navigate(ROUTES.INDEX, { replace: true });
    }
  }, []);

  const handleOpenCompany = useCallback(() => setShowCompany(true), []);

  const handleLogout = useCallback(async () => {
    try {
      await userInfo.signOut();
    } catch(e) {
    }

    navigate(ROUTES.INDEX, { replace: true });
  }, [navigate]);

  const getUser = () => {
    return userInfo.getUser().then((user) => {
      setUser(user);
      return user;
    });
  };

  useEffect(() => {
    getUser().catch(() => {
      navigate(ROUTES.AUTH, { replace: true });
    });
  }, []);

  return (
    <PrivatePage>
      <div ref={headerRef} className="trip-app-header">
        <MenuIcon onClick={handleOpen} className="menu-button"/>
        <div className="title">
          {title}
        </div>
      </div>

      <BootstrapOffcanvas show={open} onHide={handleClose}>
        <NavBar.Body>
          {user && (
            <NavBar.ProfileCard
              avatar={user.profile.getAvatar(64)}
              title={<PersonName first_name={user.profile.firstName} last_name={user.profile.lastName} middle_name={user.profile.middleName} format={PERSON_NAME_FORMAT.SHORT} />}
              body={userCompany.accountTitle}
              footer={t(`role.${userSession.role ? userSession.role : 'user'}`)}
              action={handleOpenCompany}
            />
          )}
          {userSession.account ? (
            <>
              {userSession.isCashier() && (
                <>
                  <NavBar.Item route={ROUTES.EXCURSIONS}>{t('navbar.excursions')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.BOARDING}>{t('navbar.boarding')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.MY_SALES}>{t('navbar.my_sales')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.SALARY}>{t('navbar.salary')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.CASHBOX}>{t('navbar.cashbox')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.SCHEDULE}>{t('navbar.schedule')}</NavBar.Item>
                </>
              )}
              {userSession.isGuide() && (
                <>
                  <NavBar.Item route={ROUTES.MY_ROUTES}>{t('navbar.my_routes')}</NavBar.Item>
                  <NavBar.Item route={ROUTES.ARCHIVE_ROUTES}>{t('navbar.archive_routes')}</NavBar.Item>
                </>
              )}
              {userSession.isCollector() && (
                <>
                  <NavBar.Item route={ROUTES.COLLECTION_HISTORY}>{t('navbar.collection')}</NavBar.Item>
                </>
              )}
            </>
          ) : (
            <>
              <NavBar.Item route={ROUTES.TICKETS}>{t('navbar.tickets')}</NavBar.Item>
            </>
          )}
          <NavBar.Item route={ROUTES.PROFILE}>{t('navbar.profile')}</NavBar.Item>
          <NavBar.Item onClick={handleLogout}>{t('navbar.logout')}</NavBar.Item>
        </NavBar.Body>
      </BootstrapOffcanvas>

      <Modal show={showCompany} onHide={handleCloseCompany}>
        <Modal.Body>
          <Accounts onSave={handleCloseCompany} onCancel={handleCloseCompany} />
        </Modal.Body>
      </Modal>
    </PrivatePage>
  );
};

NavBar.propTypes = {
  title: PropTypes.any,
};

const ProfileCard = ({ avatar, title, body, footer, action }) => {
  return (
    <div className="nav-bar-profile-card">
      <div className="nav-bar-profile-card-avatar">
        <div className="nav-bar-profile-card-image">
          <div className="nav-bar-profile-card-image-middle">
            {avatar && (
              <Image src={avatar} notFound={<Profile />} />
            )}
            {!avatar && (
              <Profile />
            )}
          </div>
        </div>
      </div>
      <div className="nav-bar-profile-card-info">
        <div className="nav-bar-profile-card-info-header">{title}</div>
        <div className="nav-bar-profile-card-info-body">
          {body}
        </div>
        <div className="nav-bar-profile-card-info-footer">{footer}</div>
      </div>
      <div className="nav-bar-profile-card-additional" onClick={action}>
        <DropRight />
      </div>
    </div>
  );
};

NavBar.ProfileCard = ProfileCard;

const Header = ({ children }) => {
  return (
    <BootstrapOffcanvas.Header>
      {children}
    </BootstrapOffcanvas.Header>
  );
};

NavBar.Header = Header;

const Body = ({ children }) => {
  return (
    <BootstrapOffcanvas.Body>
      {children}
    </BootstrapOffcanvas.Body>
  );
};

NavBar.Body = Body;

const Item = ({ route, onClick, children }) => {
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route, { replace: true });
  };

  return (
    <>
      {route && (
        <span onClick={(onClick ? onClick : () => handleRoute(route))} className="nav-bar-item">
          {children}
        </span>
      )}
      {!route && (
        <div onClick={(onClick ? onClick : () => {})} className="nav-bar-item">
          {children}
        </div>        
      )}
    </>
  );
};  

NavBar.Item = Item;

const Group = ({ text, children }) => {
  const groupRef = useRef(null);
  const buttonRef = useRef(null);

  const handleAccordion = () => {
    if (groupRef.current.classList.contains('nav-bar-group-show')) {
      groupRef.current.classList.replace('nav-bar-group-show', 'nav-bar-group-hide');
      buttonRef.current.classList.add('nav-bar-group-selected');
    } else {
      groupRef.current.classList.replace('nav-bar-group-hide', 'nav-bar-group-show');
      buttonRef.current.classList.remove('nav-bar-group-selected');
    }
  };

  return (
    <>
      <div ref={buttonRef} className="nav-bar-group" onClick={handleAccordion}>
        {text}
      </div>
      <div ref={groupRef} className="nav-bar-group-body nav-bar-group-hide">
        {children}
      </div>
    </>
  );
};

NavBar.Group = Group;

export default NavBar;