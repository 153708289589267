import { useState } from 'react';
import PropTypes from 'prop-types';

const Section = ({ title, className = '', children, visible = true, onVisible }) => {
  const [showBody, setShowBody] = useState(visible);

  return (
    <div className={`section ${className} ${showBody ? '' : 'hidden'}`}>
      {!!title && (
        <div className="title" onClick={() => {
          setShowBody(!showBody);
          onVisible && onVisible(!showBody);
        }}>
          {title}
        </div>
      )}
      <div className="section-body">
        {children}
      </div>
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onVisible: PropTypes.func
};

export default Section;
