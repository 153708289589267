import ClassHelper from './ClassHelper';
import Image from './Image';

class Profile extends ClassHelper {
  constructor({ _id = null, id = null, firstName, lastName, middleName = '', fullName, avatar, nickname, birthday, approved = false } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.middleName = middleName || '';
    this.fullName = fullName || `${this.firstName} ${this.lastName} ${this.middleName}`.trim();
    this.avatar = this.obj({ path: avatar }, Image);
    this.nickname = nickname;
    this.birthday = this.obj(birthday, Date);
    this.approved = approved;
  }

  getAvatar(size) {
    return this.avatar ? this.avatar.getUrl(size) : '';
  }
}

export default Profile;