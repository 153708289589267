import ClassHelper from './ClassHelper';

class Price extends ClassHelper {
  constructor({ _id = null, id = null, title, status, priceType, noSeat, appliesTo, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.status = status; // Status
    this.priceType = priceType; // PriceType
    this.noSeat = noSeat;
    this.appliesTo = this.array(appliesTo, String); // PriceAppliesTo;
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default Price;