import ExcursionPrice from './ExcursionPrice';
import PassengerActualPrice from './PassengerActualPrice';

class Passengers extends ExcursionPrice {
  constructor({ _id = null, id = null, count, actualCount, ages = [], actualPrice } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.count = count;
    this.actualCount = actualCount;
    this.ages = this.array(ages, Number);
    this.actualPrice = this.obj(actualPrice, PassengerActualPrice);
  }
}

export default Passengers;