import ClassHelper from './ClassHelper';

class PassengerActualPrice extends ClassHelper {
  constructor({ _id = null, id = null, amount, partialValue, leftToPay, discountValue } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.amount = amount;
    this.partialValue = partialValue;
    this.leftToPay = leftToPay;
    this.discountValue = discountValue;
  }
}

export default PassengerActualPrice;