import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../defs/routes';
import { Role } from '../models/types';
import { UserContext } from '../store/UserProvider';


const Index = () => {
  const navigate = useNavigate();

  const userInfo = useContext(UserContext);
  const userSession = userInfo.getSession();

  useEffect(() => {
    if (userSession && userSession.account) {
      if (!userSession || !userSession.account || !userSession.role) {
        navigate(ROUTES.ACCOUNT_SELECT, { replace: true });
      } else {
        if (userSession.role === Role.CASHIER) {
          navigate(ROUTES.EXCURSIONS, { replace: true });
        } else if (userSession.role === Role.GUIDE) {
          navigate(ROUTES.MY_ROUTES, { replace: true });
        } else if (userSession.role === Role.COLLECTOR) {
          navigate(ROUTES.COLLECTION_HISTORY, { replace: true });
        } else {
          navigate(ROUTES.PROFILE, { replace: true });
        }
      }
    } else if (userSession) {
      navigate(ROUTES.TICKETS, { replace: true });
    } else {
      navigate(ROUTES.AUTH, { replace: true });
    }
  }, [userSession, navigate]);

  return (
    <div>&nbsp;</div>
  );
};

export default Index;