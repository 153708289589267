import ClassHelper from './ClassHelper';
import Profile from './Profile';
import UserAccount from './UserAccount';

class User extends ClassHelper {
  constructor({ _id = null, id = null, profile, phone, salt = 'salt', language, email, password, accounts = [], deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.profile = this.obj(profile, Profile);
    this.phone = phone;
    this.email = email;
    this.password = password;
    this.salt = salt;
    this.language = language;
    this.accounts = this.array(accounts, UserAccount);
    this.deletedAt = this.obj(deletedAt, Date);
  }

  getAccount(accountId) {
    return this.accounts.find((a) => a.account.id === accountId) || null;
  }

  isValid() {
    return this.profile && !!this.profile.id;
  }
}

export default User;