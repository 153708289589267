import ClassHelper from './ClassHelper';
import Account from './Account';
import Limit from './Limit';
import ScheduleExcursion from './ScheduleExcursion';
import ScheduleTransport from './ScheduleTransport';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

class Schedule extends ClassHelper {
  constructor({ _id = null, id = null, account, number, excursions = [], date, limit, transports = [], status, reason, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.number = number;
    this.excursions = this.array(excursions, ScheduleExcursion);
    this.date = this.obj(date, Date);
    this.limit = this.obj(limit, Limit);
    this.transports = this.array(transports, ScheduleTransport);
    this.status = status; // ScheduleStatus
    this.reason = reason;
    this.deletedAt = this.obj(deletedAt, Date);
  }

  getDay() {
    return this.date ? this.date.getDate() : null;
  }

  getTime() {
    return this.date ? dayjs(this.date).format('HH:mm') : '';
  }

  getSeats() {
    return this.limit ? (this.limit.value - this.limit.cntSeats) : 0;
  }
}

export default Schedule;