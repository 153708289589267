import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '../../components/Alert';
import Button, { BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import SelectPassengers from './select_passengers';
import SelectDate from './select_date';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { UserContext } from '../../store/UserProvider';
import Checkbox from '../../components/Checkbox';
import { Wishes } from '../../models/types';
import API from '../../server/api';
import DateFormat, { DATE_FORMAT } from '../../components/DateFormat';
import RepositoryModel from '../../components/RepositoryModel';

dayjs.extend(utc);
dayjs.extend(timezone);

const StepCommonInfo = ({ onChange, onComplete, onError }) => {
  const {t} = useTranslation(['common', 'new_order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [schedule, setSchedule] = useState({});
  const [excursion, setExcursion] = useState({});

  const [schedules, setSchedules] = useState([]);
  const [places, setPlaces] = useState([]);
  const [prices, setPrices] = useState([]);
  const [errors, setErrors] = useState([]);

  const [commonExcursion, setCommonExcursion] = useState('');
  const [commonDate, setCommonDate] = useState('');
  const [commonTime, setCommonTime] = useState('');
  const [commonPassengers, setCommonPassengers] = useState([]);
  const [commonWishes, setCommonWishes] = useState([]);
  const [commonPlace, setCommonPlace] = useState('');
  const [commonPassport, setCommonPassport] = useState(false);

  const [showSelectPassengers, setShowSelectPassengers] = useState(false);
  const handleSelectPassengersClose = () => setShowSelectPassengers(false);
  const handleSelectPassengersShow = () => setShowSelectPassengers(true);

  const [showSelectDate, setShowSelectDate] = useState(false);
  const handleSelectDateClose = () => setShowSelectDate(false);
  const handleSelectDateShow = () => {
    setShowSelectDate(true);
  };

  const handleApplyDate = (day, time) => {
    setShowSelectDate(false);
    let date = DateFormat.Request({ date: day });
    setCommonDate(date);
    setCommonTime(time);
    fillSchedule({ date, time });
  };

  const handleChangeExcursion = ({ excursionId, scheduleId }) => {
    fillSchedule({ scheduleId, excursionId });
  };

  const handleCloseError = (uid) => {
    orderInfo.clearError('common_info', uid);
    if (uid) {
      let idx = errors.findIndex(error => error.uid === uid);
      if (idx >= 0) {
        errors.splice(idx, 1);
      }
    } else {
      errors = [];
    }
    setErrors(errors);
  };

  const fillSchedule = ({ date = commonDate, time = commonTime, scheduleId, excursionId = commonExcursion, placeId = commonPlace }) => {
    date = date || commonDate;
    time = time || commonTime;
    excursionId = excursionId || commonExcursion;
    placeId = placeId || commonPlace;

    let filters = {
      date: DateFormat.Request({ date }),
      timezone: dayjs.tz.guess(),
      subdivision: userInfo.getSession().subdivision
    };

    repository.list(API.excursion.excursions, filters).then((schedules) => {
      schedules = schedules.filter((schedule) => (schedule.excursions.reduce((count, excursion) => count + (excursion.excursion ? 1 : 0), 0) > 0));
      setSchedules(schedules);

      if (schedules.length > 0) {
        let schedule = schedules.find((schedule) => scheduleId ? schedule.id === scheduleId : (dayjs(schedule.date).tz('UTC', true).format(DATE_FORMAT.TIME) === time));
        if (!schedule) {
          schedule = schedules[0];
        }

        setSchedule(schedule);

        if (schedule) {
          let excursion = schedule.excursions.find((excursion) => excursion.excursion && (excursion.excursion.id === excursionId));
          if (!excursion) {
            excursion = schedule.excursions.length > 0 && !excursionId ? schedule.excursions[0].excursion : null;
          } else {
            excursion = excursion.excursion;
          }

          setExcursion(excursion);

          let place = null;

          if (excursion) {
            let subdivision = excursion.subdivisions.find((subdivision) => subdivision.subdivision.id === userInfo.getSession().subdivision);
            if (subdivision) {
              setPlaces([...subdivision.places]);
              setPrices([...subdivision.prices]);
              orderInfo.setPrices([...subdivision.prices]);
              place = subdivision.places.find((place) => place.place.id === placeId);
              if (place) {
                place = place.place;
              }
            } else {
              setPlaces([]);
              setPrices([]);
            }
          } else {
            setPlaces([]);
            setPrices([]);
          }

          setCommonExcursion(excursion ? excursion.id : '');
          setCommonPlace(place ? place.id : '');
        } else {
          setExcursion(null);
          setCommonExcursion('');
          setCommonPlace('');
          setPlaces([]);
          setPrices([]);
        }
      } else {
        setSchedule(null);
        setExcursion(null);
        setCommonExcursion('');
        setCommonPlace('');
        setPlaces([]);
        setPrices([]);
      }
    }).catch((e) => {
      setSchedule(null);
      setExcursion(null);
      setCommonExcursion('');
      setCommonPlace('');
      setPlaces([]);
      setPrices([]);
    });
  };

  const handleSelectPassengers = useCallback(({ passengers, wishes }) => {
    handleSelectPassengersClose();
    setCommonPassengers(passengers);
    setCommonWishes(wishes);
  }, []);

  const handleWishes = useCallback((wish, checked) => {
    let orderWishes = userInfo.getOrderSession().wishes;
    let wishIdx = orderWishes.indexOf(wish);

    if (!checked) {
      if (wishIdx >= 0) {
        orderWishes.splice(wishIdx, 1);
      }
    } else {
      if (wishIdx === -1) {
        orderWishes.push(wish);
      }
    }

    setCommonWishes(orderWishes);
  }, []);

  useEffect(() => {
    fillSchedule({
      date: userInfo.getOrderSession().date,
      time: userInfo.getOrderSession().time,
      scheduleId: userInfo.getOrderSession().schedule,
      excursionId: userInfo.getOrderSession().excursion,
      placeId: userInfo.getOrderSession().place
    });

    setCommonExcursion(userInfo.getOrderSession().excursion || '');
    setCommonDate(userInfo.getOrderSession().date || '');
    setCommonTime(userInfo.getOrderSession().time || '');
    setCommonWishes(userInfo.getOrderSession().wishes || []);
    setCommonPassengers(userInfo.getOrderSession().passengers || []);
    setCommonPlace(userInfo.getOrderSession().place || '');
    setCommonPassport(!!userInfo.getOrderSession().put_passport);

    orderInfo.setCommonInfo({ subdivision: userInfo.getOrderSession().subdivision });
  }, []);

  const validate = async (info) => {
    let errors = await orderInfo.validate(STEPS.common_info, info);
    setErrors(errors);
  };

  useEffect(() => {
    const info = {
      excursion: commonExcursion,
      date: commonDate,
      time: commonTime,
      passengers: commonPassengers,
      wishes: commonWishes,
      place: commonPlace,
      put_passport: commonPassport,
      schedule: schedule ? schedule.id : null
    };

    orderInfo.setCommonInfo(info);
    userInfo.setOrderSession(info);

    if (commonExcursion) {
      validate(info);
    }
  }, [commonExcursion, commonDate, commonTime, commonPassengers, commonWishes, commonPlace, commonPassport]);

  return (
    <>
      <div>
        {errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}

        <div className="input">
          <label className="field-label">{t('new_order:common_info.excursion')}</label>
          <select className="input" value={commonExcursion} onChange={(e) => { handleChangeExcursion({ excursionId: e.target.value, scheduleId: e.target.querySelector('option:checked').getAttribute('data-schedule') }); }}>
            {schedules.map((schedule) => (
              schedule.excursions.map((excursion) => (
                excursion.excursion && excursion.subdivisions.find((subd) => subd.subdivision.id === userInfo.getSession().subdivision) && (
                  <option key={excursion.excursion.id} value={excursion.excursion.id} data-schedule={schedule.id}>{excursion.excursion.title}</option>
                )
              ))
            ))}
          </select>
        </div>

        {excursion && excursion.passportRequired && (
          <div className="input">
            <div>{t('new_order:common_info.passport_data')}</div>
            <Checkbox.Slide title={t('new_order:common_info.put_client')} checked={!commonPassport} onChange={(value, checked) => setCommonPassport(!checked)} />
          </div>
        )}

        <div className="input">
          <label className="field-label">{t('new_order:common_info.date_departure')}</label>
          <Button variant={BUTTON_TYPE.SECONDARY} onClick={handleSelectDateShow}>
            <DateFormat.Day date={commonDate} />, {commonTime}
          </Button>
        </div>

        <div className="input">
          <label className="field-label">{t('new_order:common_info.passengers')}</label>
          {excursion && excursion.passportRequired && commonPassport && (
            <>
              {commonPassengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === commonPassengers.reduce((count, passenger) => count + passenger.count, 0) ? (
                <span className="ready-note">{t('new_order:common_info.ready_passport')}</span>
              ) : (
                <span className="alert-note">{t('new_order:common_info.put_passport')}</span>
              )}
            </>
          )}
          <Button variant={BUTTON_TYPE.SECONDARY} onClick={handleSelectPassengersShow}>
            {commonPassengers.map((passenger) => (
              <div className="passenger-price" key={passenger.excursion_price}>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></div>
            ))}
          </Button>
          <Checkbox.Group>
            {Object.values(Wishes).map((wish) => (
              <Checkbox.Button key={wish} title={t(`new_order:wishes.${wish}`)} checked={commonWishes.indexOf(wish) >= 0} value={wish} onChange={handleWishes} />
            ))}
          </Checkbox.Group>
        </div>

        <div className="input">
          <label className="field-label">{t('new_order:common_info.seat')}</label>
          <select className="input" value={commonPlace} onChange={(e) => { setCommonPlace(e.target.value); }}>
            <option value={-1}>{t('new_order:common_info.none')}</option>
            {places.map((place) => (
              <RepositoryModel key={place.place.id} type="Place" uid={place.place.id} render={(model) => (
                <option key={place.place.id} value={model.id}>{model.title} - {place.getTime()}</option>
              )}/>
            ))}
          </select>
        </div>
      </div>

      <Modal show={showSelectPassengers} onHide={handleSelectPassengersClose}>
        <Modal.Header>{t('new_order:select_passengers.title')}</Modal.Header>
        <Modal.Body>
          {showSelectPassengers && (
            <SelectPassengers
              prices={prices}
              limits={{seats: (schedule ? schedule.limit.value - schedule.limit.cntSeats : 0)}}
              put_passport={commonPassport}
              passengers={commonPassengers}
              wishes={commonWishes}
              onApply={handleSelectPassengers}
              onCancel={handleSelectPassengersClose}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showSelectDate} onHide={handleSelectDateClose}>
        <Modal.Header>{t('new_order:select_date.title')}</Modal.Header>
        <Modal.Body>
          {showSelectDate && (
            <SelectDate onApply={handleApplyDate} onCancel={handleSelectDateClose}/>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

StepCommonInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepCommonInfo;