import { OrderProvider } from '../../store/OrderProvider';
import OrderForm from './order_form';

const NewOrder = () => {
  return (
    <OrderProvider>
      <OrderForm />
    </OrderProvider>
  )
};

export default NewOrder;