import ClassHelper from './ClassHelper';

class CategorySeo extends ClassHelper {
  constructor({ title, description } = {}) {
    super(...arguments);
    this.title = title;
    this.description = description;
  }
}

export default CategorySeo;