import PropTypes from 'prop-types';

export const BADGE_TYPE = {
  INFO: 'secondary',
  WARN: 'warning',
  ERROR: 'danger',
  SUCCESS: 'success',
};

const Badge = ({ type = BADGE_TYPE.PRIMARY, className = '', children }) => {
  return (
    <span className={`badge badge-${type} ${className}`}>{children}</span>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
};

export default Badge;