import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import OneTimeCode from '../../components/OneTimeCode';
import { env } from '../../utils/util';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Phone from '../../components/Phone';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { useContext } from 'react';
import API from '../../server/api';
import { SmartCaptcha } from '@yandex/smart-captcha';
import Row from '../../components/Row';
import InputWithError from '../../components/InputWithError';

const PHASES = {
  PHONE: 0,
  CAPTCHA: 1,
  SMS_CODE: 2,
  NEW_PASSWORD: 3,
  SUCCESS: 4
}

const SMS_CODE_LENGTH = env('REACT_APP_SMS_CODE_LENGTH') ? parseInt(env('REACT_APP_SMS_CODE_LENGTH')) : 6;

const ForgotPassword = ({ onSubmit }) => {
  const {t} = useTranslation(['common', 'auth']);

  const repository = useContext(RepositoryContext);

  const [phone, setPhone] = useState({ value: '', error: false });
  const [smsCode, setSmsCode] = useState({ value: '', error: false });
  const [newPassword, setNewPassword] = useState({ value: '', error: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: false });
  const [phase, setPhase] = useState(PHASES.PHONE);
  const [captcha, setCaptcha] = useState({ value: '', error: false });
  const [token, setToken] = useState({ value: '', error: false });
  const otcRef = useRef(null);

  const handleNext = (e) => {
    e.preventDefault();

    if (phase === PHASES.PHONE) {
      if (Phone.validate(phone.value)) {
        setPhase(PHASES.CAPTCHA);
      } else {
        setPhone({ value: phone.value, error: t('auth:error.invalid_phone')});
      }
    } else if (phase === PHASES.CAPTCHA) {
      if (captcha.value) {
        repository.action(API.user.sendCode, { phone: phone.value, captcha }).then(() => {
          setPhase(PHASES.SMS_CODE);
        }).catch((e) => {
          setPhase(PHASES.SMS_CODE);
          setCaptcha({ value: '', error: e.error });
        });
      }
    } else if (phase === PHASES.SMS_CODE) {
      repository.action(API.user.validateCode, { phone: phone.value, code: smsCode.value }).then((answer) => {
        if (answer.success) {
          setToken({ value: answer.token, error: false });
          setPhase(PHASES.NEW_PASSWORD);
        }
      }).catch((e) => {
        setToken({ value: '', error: e.error });
      });
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();

    if (phase === PHASES.SMS_CODE) {
      setPhase(PHASES.CAPTCHA);
    } else if (phase === PHASES.CAPTCHA) {
      setPhase(PHASES.PHONE);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (phase === PHASES.NEW_PASSWORD) {
      if (!newPassword.value) {
        setNewPassword({ value: newPassword.value, error: t('auth:error.empty_password') });
      } else if (newPassword.value !== confirmPassword.value) {
        setConfirmPassword({ value: confirmPassword.value, error: t('auth:error.password_no_match') });
      } else {
        repository.action(API.auth.setPassword, { token: token.value, password: newPassword.value }).then((answer) => {
          setToken(answer.token);
          setPhase(PHASES.SUCCESS);
        }).catch((e) => {
          setNewPassword({ value: newPassword.value, error: t('auth:error.user_no_password') });
        });
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();

    if (phase === PHASES.NEW_PASSWORD) {
      onSubmit(false);
    }
    setPhase(PHASES.PHONE);
  };

  return (
    <>
      {(phase === PHASES.PHONE) && (
        <Row>
          <Phone.Input id="phone" name="phone" className="text form-control" placeholder={t('auth:form.phone_placeholder')} label={t('auth:form.phone')}
            value={phone.value}
            error={phone.error}
            onChange={(val) => {
              setPhone({
                value: val,
                error: !val,
              });
            }}
          />
        </Row>
      )}

      {(phase === PHASES.CAPTCHA) && (
        <Row>
          <SmartCaptcha sitekey={env('REACT_APP_SMARTCAPTCHA_CLIENT')} onSuccess={(value) => setCaptcha({ value, error: false })} />
        </Row>
      )}

      {(phase === PHASES.SMS_CODE) && (
        <Row>
          <label className="field-label">{t('auth:form.sms_code')}</label>
          <OneTimeCode
            ref={otcRef}
            className="text"
            value={smsCode.value}
            name="sms_code"
            length={SMS_CODE_LENGTH}
            onChange={(val) => {
              setSmsCode({
                value: val,
                error: !val,
              });
            }}
          />
        </Row>
      )}

      {(phase === PHASES.NEW_PASSWORD) && (
        <>
          <Row>
            <InputWithError type="password" label={t('auth:form.new_password')}
              value={newPassword.value}
              error={newPassword.error}
              name="new_password"
              onChange={(val) => {
                setNewPassword({
                  value: val,
                  error: !val,
                });
              }}
            />
          </Row>
          <Row>
            <InputWithError type="password" label={t('auth:form.confirm_password')}
              value={confirmPassword.value}
              error={confirmPassword.error}
              name="confirm_password"
              onChange={(val) => {
                setConfirmPassword({
                  value: val,
                  error: !val,
                });
              }}
            />
          </Row>
        </>
      )}
      {(phase === PHASES.SUCCESS) && (
        <Row>
          {t('auth:form.success')}
        </Row>
      )}

      <Row>
        <Button.Panel>
          {(phase === PHASES.SMS_CODE || phase === PHASES.CAPTCHA) && (
            <>
              <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.LEFT} onClick={handlePrev}>{t('auth:button.prev')}</Button>
              <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.RIGHT} onClick={handleNext}>{t('auth:button.next')}</Button>
            </>
          )}
          {(phase === PHASES.PHONE) && (
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleNext}>{t('auth:button.next')}</Button>
          )}
          {(phase === PHASES.NEW_PASSWORD) && (
            <>
              <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleSave}>{t('auth:button.save')}</Button>
              <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={handleCancel}>{t('auth:button.cancel')}</Button>
            </>
          )}
          {(phase === PHASES.SUCCESS) && (
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={() => onSubmit(true)}>{t('auth:button.entry')}</Button>
          )}
        </Button.Panel>
      </Row>
    </>
  )
}

export default ForgotPassword;