import ClassHelper from './ClassHelper';
import User from './User';

class Seller extends ClassHelper {
  constructor({ _id = null, id = null, type, user } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.type = type; // SellerType
    this.user = this.ref(user, User);
  }
}

export default Seller;