import PropTypes from 'prop-types';

const ErrorHint = (props) => {
  if (props.error !== false) {
    return (
      <div className="error-hint text-danger">{props.error}</div>
    );
  }

  return null;
};

ErrorHint.propTypes = {
  error: PropTypes.any
};

export default ErrorHint;
