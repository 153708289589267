import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const DATE_FORMAT = {
  DAY: 'DD.MM.YYYY',
  TIME: 'HH:mm',
  REQUEST: 'YYYY-MM-DD',
  FULL: 'DD.MM.YYYY HH:mm:ss'
};

export const MONTHS = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11
};

const DateFormat = ({ date = new Date(), format = DATE_FORMAT.DAY, parse = false }) => {
  const day = typeof date === 'string' ? (parse ? dayjs(date, parse) : dayjs(date)) : dayjs(date);

  return day.format(format);
};

DateFormat.propTypes = {
  format: PropTypes.string,
  parse: PropTypes.string,
  date: PropTypes.any.isRequired
};

const DateTime = ({ date }) => {
  const {t} = useTranslation(['common']);

  const day = dayjs(date);

  return t('common:date.full', {date: `${day.date()} ${t(`common:date.months.${day.month()}`)} ${day.year()}`, time: day.format(DATE_FORMAT.TIME)});
};

DateTime.propTypes = {
  date: PropTypes.any.isRequired
};

DateFormat.DateTime = DateTime;

const Day = ({ date, parse = false }) => {
  const {t} = useTranslation(['common']);

  let day = typeof date === 'string' ? dayjs(date, parse) : dayjs(date);
  if (!day.isValid()) {
    day = dayjs(date);
  }

  return `${day.date()} ${t(`common:date.months.${day.month()}`)} ${day.year()}`;
};

Day.propTypes = {
  parse: PropTypes.string,
  date: PropTypes.any.isRequired
};

DateFormat.Day = Day;

const Time = ({ date }) => {
  const day = dayjs(date);

  return day.format(DATE_FORMAT.TIME);
};

Time.propTypes = {
  date: PropTypes.any.isRequired,
};

DateFormat.Time = Time;

const Request = ({ date }) => {
  const day = dayjs(date);

  return day.format(DATE_FORMAT.REQUEST);
};

Request.propTypes = {
  date: PropTypes.any.isRequired,
};

DateFormat.Request = Request;

export default DateFormat;