import ClassHelper from './ClassHelper';
import BusFloor from './BusFloor';
import { TransportType } from './types';

class TransportConfig extends ClassHelper {
  constructor({ _id = null, id = null, type, isCustom, floors = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.type = type; // TransportType
    if (type === TransportType.BUS) {
      this.isCustom = isCustom;
      this.floors = this.array(floors, BusFloor);
    }
  }
}

export default TransportConfig;