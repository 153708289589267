import FixtureAuth from './auth';
import FixtureUser from './user';
import { getJSON } from '../utils/json';

const Fixture = {
  auth: FixtureAuth,
  user: FixtureUser
};

export const callFixture = (fixture, apiParams) => {
  let params = {
    params: apiParams,
    store: getStore()
  };
  /* eslint-disable no-new-func */
  let response = getJSON(new Function('let store = this.store; let params = this.params; return `' + fixture + '`;').call(params), {});
  return { data: response };
};

export const getStore = () => {
  return store1;
};

const store1 = {
  user: {
    profile: {
      firstName: "Qwe",
      lastName: "Wer",
      middleName: "Sdf",
      birthday: "1990-11-04T22:00:00.000Z",
      approved: true,
      id: "9a183cfe4ee8418fb69f60ded7a3722d"
    },
    phone: "+7921108143622",
    language: "ru",
  },
  accounts: [
    {
      account: "65ee54077c07cf3a770dcd95",
      accountType: "trip-company",
      roles: [
        "cashier",
        "driver",
        "guide",
        "collector"
      ],
      status: "active",
      id: "65eec46cc623fb4e1b5d6840",
      accountTitle: "TEST COMPANY 1",
      accountUrl: "kira-liza-monmatra"
    }
  ],
  excursions: [
    {
      id: "9a183cfe4ee8418fb69f60ded7a3722d",
      type: "bus",
      pickupType: "place",
      title: "Bus excursion 1",
      url: "http://bus.excursion.one",
      moderation: {
        status: "approved",
        comment: "haha"
      },
      categories: [{ _id: "3d88275c2a16401aa66c314b87e54e45", title: "Category 1" }],
      freeServices: [{ _id: "a3c5045f06794fda8b1a6e74f59c7ed3", title: "Service 1"}],
      canBeCancelledBefore: false,
      subdivisions: [{ subdivision: { _id: "86a8556627b848e59e15ccb712c3f468", title: "Subdivision 1" } }],
    }
  ]
};

export default Fixture;