import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoginForm from './login_form';
import ForgotPassword from './forgot_password';

const TAB_INDEX = {
  LOGIN: 0,
  FORGOT_PASSWORD: 1
};

const Login = () => {
  const {t} = useTranslation(['common', 'auth']);

  const [tabIndex, setTabIndex] = useState(0);

  function handleLogin() {
  }

  function handleForgotPassword() {
    setTabIndex(TAB_INDEX.LOGIN);
  }

  return (
    <div className="trip-app">
      <Tabs className="Tabs" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className={`trip-app-header`}>
          <TabList className={`react-tabs__tab-list`}>
            <Tab disabled={false}>{t('auth:tab.entry')}</Tab>
            <Tab disabled={false}>{t('auth:tab.forgot_password')}</Tab>
          </TabList>
        </div>
        <div className="trip-app-body">
          <TabPanel>
            <LoginForm onSubmit={handleLogin}/>
          </TabPanel>
          <TabPanel>
            <ForgotPassword onSubmit={handleForgotPassword}/>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  )
}

export default Login;