import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { formatPhoneNumber, getCountries, isValidPhoneNumber } from 'react-phone-number-input/input'
import ErrorHint from './ErrorHint';

export const PHONE_FORMAT_COUNTRY = getCountries().reduce((acc, country) => { acc[country] = country; return acc; }, {});

const Phone = ({ value = 0, className = '' }) => {
  return (
    <span className={`phone ${className}`}>{formatPhoneNumber(value)}</span>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired
};

const Input = ({ className = '', name, label, placeholder, error, value, onInput, onChange, onEnter, country = PHONE_FORMAT_COUNTRY.RU, reff }) => {
  return (
    <>
      {label && (
        <label className="field-label">{label}</label>
      )}
      <PhoneInput className={`text ${className}`} value={value} defaultCountry={country} placeholder={placeholder} name={name}
        onInput={(e) => {
          onInput && onInput(e.target.value);
        }}
        ref={reff}
        onChange={(val) => {
          onChange && onChange(val);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && typeof onEnter === 'function') {
            e && e.preventDefault();
            onEnter && onEnter(e.target.value.trim());
          }
        }}
      />
      {error && (
        <ErrorHint error={error} />
      )}
    </>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  country: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  reff: PropTypes.any
};

Phone.Input = Input;

const Text = ({ phone }) => {
  return formatPhoneNumber(phone);
};

Text.propTypes = {
  phone: PropTypes.string
};

Phone.Text = Text;

Phone.validate = (value) => {
  return isValidPhoneNumber(value);
};

export default Phone;