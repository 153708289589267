import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import { UserContext } from '../../store/UserProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import Point from '../../models/Point';
import Field from '../../components/Field';
import log from '../../utils/logger';
import Report from '../../models/Report';
import { ROUTES } from '../../defs/routes';

const Accounts = ({ onSave, onCancel }) => {
  const {t} = useTranslation(['common', 'account']);

  const navigate = useNavigate();

  const repository = useContext(RepositoryContext);
  const userInfo = useContext(UserContext);

  const [roles, setRoles] = useState([]);

  const [user, setUser] = useState(null);
  const [point, setPoint] = useState({});
  const [account, setAccount] = useState(null);
  const [role, setRole] = useState(null);

  const getUser = () => {
    return userInfo.getUser().then((user) => {
      setUser(user);
      return user;
    }).catch((e) => {
      navigate(ROUTES.AUTH, { replace: true });
      return Promise.reject(e);
    });
  };

  const getPoints = async () => {
    if (userInfo.getSession().point) {
      repository.request(API.point.point, { id: userInfo.getSession().point, accountId: account }, Point).then((point) => {
        setPoint(point);
      }).catch((error) => {
        log.error(error);
        setPoint({});
      });
    } else {
      setPoint({});
    }
  };

  useEffect(() => {
    getUser();
    setAccount(userInfo.getSession().account);
    setRole(userInfo.getSession().role);
  }, []);

  useEffect(() => {
    if (account && user) {
      if (userInfo.getSession().isCashier()) {
        getPoints();
      }

      let acc = user.getAccount(account);
      if (acc) {
        setRoles(acc.roles);
      } else {
        setRoles([]);
      }
    } else {
      setRoles([]);
    }
  }, [account]);

  const handleSave = useCallback(() => {
    let needReload = userInfo.getSession().role !== role || userInfo.getSession().point !== point.id || userInfo.getSession().account !== account;

    userInfo.setSessionPoint(userInfo.getSession().isCashier() ? point.id : null);
    userInfo.setSessionAccount(account);
    userInfo.setSessionRole(role);
    userInfo.setSessionSubdivision(userInfo.getSession().isCashier() ? point.subdivision.id : null);

    onSave && onSave(needReload);
  }, [point, account, role]);

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  const handlePointAutocomplete = useCallback(async (query) => {
    let filters = query ? { title: { $regex: query, $options: 'i' } } : {};
    let reportFilters = new Report({filters});
    try {
      let report = await repository.report(API.point.report, reportFilters, Point);
      return report.rows;
    } catch(e) {
      return [];
    }
  }, []);

  return (
    <div className="trip-app">
      <header className={`trip-app-header`}>
        <h1>{t('account:accounts.title')}</h1>
      </header>
      <div className="trip-app-body">
        <Field title={t('account:accounts.company')}>
          <select className="input" onChange={(e) => { setAccount(e.target.value); }}>
            {user && user.accounts && user.accounts.map((acc) => (
              <option key={acc.id} value={acc.id}>{acc.accountTitle}</option>
            ))}
          </select>
        </Field>
        <Field title={t('account:accounts.role')}>
          <select className="input" value={role} onChange={(e) => { setRole(e.target.value); }}>
            {roles && roles.map((role) => (
              <option key={role} value={role}>{t(`role.${role}`)}</option>
            ))}
          </select>
        </Field>
        {userInfo.getSession().isCashier() && (
          <div>
            <label className="field-label">{t('account:accounts.point')}</label>
            <SelectAutocomplete
              apiHandler={handlePointAutocomplete}
              template={(p) => `${p.title} (${p.subdivision.title})`}
              value={point.title}
              onChange={setPoint}
              getValue={(row) => row ? row.title : ''}
            />
          </div>
        )}
      </div>
      <footer className="trip-app-footer">
        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleSave}>{t('common:button.apply')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </footer>
    </div>
  )
}

Accounts.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Accounts;