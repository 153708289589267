import ClassHelper from './ClassHelper';

class Location extends ClassHelper {
  constructor({ _id = null, id = null, type, coordinates = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.type = type;
    this.coordinates = coordinates;
  }
}

export default Location;