import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { MODAL_TYPE } from './Modal';

const KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13
};

const SelectAutocomplete = ({ value, className = '', template, getValue = (value) => value, apiHandler, onChange }) => {
  const [showAutocompleteModal, setShowAutocompleteModal] = useState(false);

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [item, setItem] = useState('');

  const queryRef = useRef();
  const valueRef = useRef();

  const handleInput = (event) => {
    let row = selectedRow;
    if (event.keyCode === KEYS.DOWN) {
      ++row;
      setSelectedRow((row < 0 || row >= rows.length) ? 0 : row);
    } else if (event.keyCode === KEYS.UP) {
      --row;
      setSelectedRow(row < 0 ? (rows.length ? (rows.length - 1) : -1) : row);
    } else if (event.keyCode === KEYS.ENTER) {
      if (selectedRow >= 0 && selectedRow < rows.length) {
        queryRef.current.value = getValue(rows[selectedRow]);
      }
    }
  };

  const handleAutocomplete = useCallback(async (query) => {
    let _rows = await apiHandler(query);
    setRows(_rows);
  }, []);

  const handleCloseAutocompleteModal = useCallback(() => {
    setShowAutocompleteModal(false);
  }, []);

  const handleApplyValue = useCallback(() => {
    let row = selectedRow;
    if (row < 0 && rows.length > 0) {
      row = 0;
    }

    if (row >= 0) {
      onChange(rows[row]);
      setItem(getValue(rows[row]));
      valueRef.current.value = getValue(rows[row]);
    } else {
      setItem('');
    }

    setShowAutocompleteModal(false);
  }, [selectedRow]);

  const handleShowModal = useCallback(() => {
    setShowAutocompleteModal(true);
    if (queryRef.current) {
      queryRef.current.value = '';
    }
  }, [showAutocompleteModal]);

  const handleSelectRow = useCallback((item, i, id, value) => {
    onChange(item || {});
    valueRef.current.value = value;
    setItem(getValue(item));
    setSelectedRow(i);
    setShowAutocompleteModal(false);
  }, []);

  useEffect(() => {
    handleAutocomplete('');
  }, []);

  useEffect(() => {
    setItem(value);
  }, [value]);

  return (
    <>
      <div className={`input-group ${className}`}>
        <input ref={valueRef} type="text" readOnly={true} value={item} className="form-control"/>
        <span className="input-group-text" onClick={handleShowModal}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{width: '24px', height: '24px'}} viewBox="0 0 16 16" fill="#000000"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>
        </span>
      </div>
      <Modal show={showAutocompleteModal} type={MODAL_TYPE.INPUT} onHide={handleCloseAutocompleteModal}>
        <Modal.Body>
          <div className="input-group">
            <input className="form-control" ref={queryRef} autoFocus onInput={(e) => handleAutocomplete(e.target.value)} onKeyDown={handleInput}/>
            <span className="input-group-text" onClick={handleApplyValue}>OK</span>
          </div>
          <div>
            {rows.map(((row, i) => (
              <div className={selectedRow === i ? 'selected-row' : ''} key={i} id={row.id} onClick={(e) => {handleSelectRow(row, i, row.id, getValue(row));}}>
                {template ? template(row, i) : getValue(row)}
              </div>
            )))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

SelectAutocomplete.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  apiHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  template: PropTypes.any,
  getValue: PropTypes.func
};

export default SelectAutocomplete;