import ClassHelper from './ClassHelper';
import Account from './Account';
import ObjectClass from './ObjectClass';

class AccountObject extends ClassHelper {
  constructor({ _id = null, id = null, account, object, title, status, deletedAt } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.account = this.ref(account, Account);
    this.object = this.obj(object, ObjectClass);
    this.title = title;
    this.status = status; // Status
    this.deletedAt = this.obj(deletedAt, Date);
  }
}

export default AccountObject;