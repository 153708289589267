import ClassHelper from './ClassHelper';
import BusPlace from './BusPlace';

class BusFloor extends ClassHelper {
  constructor({ _id = null, id = null, countSeats, countRows, countCols, places = [] } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.countSeats = countSeats;
    this.countRows = countRows;
    this.countCols = countCols;
    this.places = this.array(places, BusPlace);
  }
}

export default BusFloor;