import { env } from '../utils/util';
import ClassHelper from './ClassHelper';

class File extends ClassHelper {
  constructor({ _id = null, id = null, title, path, type, description, moderationMessage, moderationStatus, isConverted, issue } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.title = title;
    this.path = path;
    this.type = type; // FileType
    this.description = description;
    this.moderationMessage = moderationMessage;
    this.moderationStatus = moderationStatus; // ModerationStatus
    this.isConverted = isConverted;
    this.issue = issue;
  }

  getUrl(size = 64) {
    return this.path ? `${env('REACT_APP_TRIP_COMPANY_API_SERVER_URL')}/content/${this.path}/${size || 64}.webp?rnd=${new Date().getTime()}`.replace(/(?<!(http:|https:))\/\//g, '/') : '';
  }
}

export default File;