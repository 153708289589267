export const ROUTES = {
  INDEX: '/',
  AUTH: '/auth',
  ACCOUNTS: '/accounts',
  SCHEDULE: '/schedule',
  EXCURSIONS: '/excursions',
  EXCURSION_INFO: '/excursion_info',
  BOARDING: '/boarding',
  NEW_ORDER: '/new_order',
  MY_SALES: '/my_sales',
  ORDER: '/order',
  PLACES: '/places',
  SALARY: '/salary',
  CASHBOX: '/cashbox',
  MY_ROUTES: '/my_routes',
  ROUTES: '/routes',
  TAKE_PLACE: '/take_place',
  TICKET: '/ticket',
  ARCHIVE_ROUTES: '/archive_routes',
  COLLECTION_HISTORY: '/collection_history',
  NEW_COLLECTION: '/new_collection',
  PROFILE: '/profile',
  LOGOUT: '/logout',
  TICKETS: '/tickets',
  UI_EXAMPLE: '/ui_example'
};